function percentageDifference(num1, num2) {
    // Calculate the absolute difference between the two numbers
    const difference = Math.abs(num1 - num2);
    
    // Calculate the average of the two numbers to use as a base for the percentage
    const average = (num1 + num2) / 2;
    
    // Calculate the percentage difference
    const percentageDiff = (difference / average) * 100;
    
    // Return the percentage difference rounded to the nearest whole number
    return Math.round(percentageDiff);
}

export default percentageDifference;