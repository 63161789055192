import React, { useReducer, createContext } from "react";

export const LandingContext = createContext();

const initialState = {
	formVisible: false,
	openForm: () => { },
	closeForm: () => { },
	landingMode: "mode_a"
};

const reducer = (state, action) => {
	switch (action.type) {
		case 'SET_FORM_VISIBLE':
			return {
				...state,
				formVisible: action.payload
			};
		case 'SET_OPEN_FORM':
			return {
				...state,
				openForm: action.payload
			};
		case 'SET_CLOSE_FORM':
			return {
				...state,
				closeForm: action.payload
			};
		case 'SET_LANDING_MODE':
			return {
				...state,
				landingMode: action.payload
			};
		default:
			return {
				state
			};
	}
};

export const LandingProvider = props => {
	const [state, dispatch] = useReducer(reducer, initialState);
	return (
		<LandingContext.Provider value={[state, dispatch]}>
			{props.children}
		</LandingContext.Provider>
	);
};