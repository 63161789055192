import colors from 'theme/colors';

let sx = {
	mainContainer: {
		backgroundColor: colors.light_background,
		paddingTop: "48px",
		paddingBottom: "48px",
		display: "flex",
		flexDirection: "column",
		alignItems: "center"
	},
	mainContainerMobile: {
		backgroundColor: colors.light_background,
		paddingTop: "16px",
		paddingBottom: "16px",
		display: "flex",
		flexDirection: "column",
		alignItems: "center"
	},
	mainGrid: {
		// marginTop: "85px",
		// marginBottom: "48px",
	},
	gridItem: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
	},
	infoCard: {
		maxWidth: "332px",
		width: "100%",
		padding: "16px",
		borderRadius: "24px",
		marginTop: "48px",
		marginLeft: "8px", 
		marginRight: "8px"
	},
	infoCardMobile: {
		maxWidth: "302px",
		padding: "16px",
		borderRadius: "24px",
		marginTop: "24px"
	},
	infoImage: {
		height: "215px",
		borderRadius: "12px",
		overflow: "hidden",
		marginBottom: "16px"
	},
	arrowInfo: {
		fontSize: "40px",
		marginTop: "48px",
		color: colors.primary_green
	}

}

export default sx;