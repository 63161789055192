module.exports = `<!DOCTYPE html>
<html>
<head>
	<meta http-equiv="content-type" content="text/html; charset=utf-8"/>
	<title></title>
	<meta name="generator" content="LibreOffice 7.6.3.2 (Linux)"/>
	<meta name="created" content="00:00:00"/>
	<meta name="changed" content="00:00:00"/>
	<style type="text/css">
		@page { size: 8.5in 11in; margin: 1in }
		p { line-height: 115%; text-align: left; orphans: 2; widows: 2; margin-bottom: 0.1in; direction: ltr; background: transparent }
	</style>
</head>
<body lang="en-US" link="#000080" vlink="#800000" dir="ltr">
    <div ng-controller="privacyTermsInternalController as vm" class="container-fluid termsAndConditions-styles">
    <div class="row">
        <div class="col-xs-12">
            <div class="row">
                <div class="col-xs-12">
                    <div class="div-containerTermsAndConditions">
                        <span class="modaltitle-termscond">
                        <font face="Times New Roman, serif"><font size="1" style="font-size: 12pt">
                            AVISO DE PRIVACIDAD TREEMBO
                        </font></font>
                        </span>
                        <br>
                        <br>
                        <span class="modaltitle-termscond">
                        <font face="Times New Roman, serif"><font size="1" style="font-size: 12pt">
                            Dirección de correo electrónico en la que puede contactarse con nuestro líder de privacidad.
                        </font></font>
                        </span>
                        <br>
                        <br>
                        <span class="modaltext-contentTermsCond">
                        <font face="Times New Roman, serif"><font size="1" style="font-size: 12pt">
                            Para todo lo relacionado con el tratamiento y protección de sus datos personales, usted
                            podrá contactarse con nuestro líder de privacidad enviando un correo electrónico a la
                            siguiente dirección:
                            <a target="_blank" href="mailto:contacto@treembo.com">yair@treembo.com</a>.
                        </font></font>
                        </span>
                        <br>
                        <br>
                        <span class="modaltitle-termscond">
                        <font face="Times New Roman, serif"><font size="1" style="font-size: 12pt">
                            FINALIDADES PARA LAS QUE UTILIZAREMOS SUS DATOS PERSONALES
                        </font></font>
                        </span>
                        <br>
                        <br>
                        <span class="modaltext-contentTermsCond">
                        <font face="Times New Roman, serif"><font size="1" style="font-size: 12pt">
                            Con “datos personales” se hace referencia a cualquier información que identifique o que
                            pueda ser usada para contactar o localizar a quien la información pertenece.
                            <br>

                            La información personal que recolectamos estará sujeta a esta política de privacidad, y será
                            modificada periódicamente.
                            <br>
                            <br>
                            Su información personal será utilizada para las siguientes finalidades, las cuales son
                            necesarias con relación a su contratación:
                            <br>
                            <br>

                            <ul>
                                <li>
                                    Validar la documentación e información proporcionada por usted.
                                </li>
                                <li>
                                    Gestionar su alta en nuestros sistemas e integrar su expediente.
                                </li>
                                <li>
                                    Contactarlo con motivo del desarrollo y gestión de sus servicios.
                                </li>
                                <li>
                                    Efectuar los cobros correspondientes a nuestros servicios.
                                </li>
                                <li>
                                    Cumplir de manera eficaz el servicio contratado.
                                </li>
                                <li>
                                    Mejorar la calidad y el rendimiento del servicio.
                                </li>
                            </ul>
                            <br>
                            <br>
                            Si usted no desea que sus datos personales sean tratados para las finalidades señaladas,
                            puede negarnos su consentimiento desde este momento enviando un correo electrónico a nuestro
                            líder de privacidad, en el que especifique la o las finalidades secundarias para las que no
                            desea que utilicemos sus datos personales. Su negativa no será motivo ni afectará los
                            derechos y obligaciones entre usted y esta empresa.
                        </font></font>
                        </span>
                        <br>
                        <br>
                        <span class="modaltitle-termscond">
                        <font face="Times New Roman, serif"><font size="1" style="font-size: 12pt">
                            Datos personales que se recabarán.
                        </font></font>
                        </span>
                        <br>
                        <br>
                        <span class="modaltext-contentTermsCond">
                        <font face="Times New Roman, serif"><font size="1" style="font-size: 12pt">
                            Para llevar a cabo las finalidades del servicio, utilizaremos las siguientes categorías de
                            datos personales, las cuales serán solicitadas al usuario, quien voluntariamente nos los
                            provee cuando accede a las características que los requieran:
                            <br>
                            <br>

                            <ul>
                                <li>
                                    Identificación y contacto.
                                </li>
                                <li>
                                    Referencias laborales.
                                </li>
                                <li>
                                    Financieros y de pago.
                                </li>
                                <li>
                                    Información general.
                                </li>
                                <li>
                                    Credenciales de conexión y autenticación a sistemas internos o cuentas de
                                    integraciones autorizadas. A su vez, se accederá a la información a la que el
                                    usuario haya otorgado consentimiento para ser compartida con Treembo desde estas
                                    integraciones.
                                </li>
                            </ul>
                        </font></font>
                        </span>
                        <br>
                        <br>
                        <span class="modaltitle-termscond">
                        <font face="Times New Roman, serif"><font size="1" style="font-size: 12pt">
                            Información del dispositivo
                        </font></font>
                        </span>
                        <br>
                        <br>
                        <span class="modaltext-contentTermsCond">
                        <font face="Times New Roman, serif"><font size="1" style="font-size: 12pt">
                            Se recaba información del dispositivo (computadora, tableta, teléfono móvil, etcétera) como
                            tipo de conexión, configuración, navegador, horarios de acceso o dirección IP cuando se
                            instala, accede o actualiza a nuestros servicios con la finalidad de brindar un mejor
                            servicio para nuestros usuarios.
                        </font></font>
                        </span>
                        <br>
                        <br>
                        <span class="modaltitle-termscond">
                        <font face="Times New Roman, serif"><font size="1" style="font-size: 12pt">
                            Permisos del dispositivo.
                        </font></font>
                        </span>
                        <br>
                        <br>
                        <span class="modaltext-contentTermsCond">
                        <font face="Times New Roman, serif"><font size="1" style="font-size: 12pt">
                            Para llevar a cabo las finalidades del servicio, se requiere acceso a los siguientes
                            permisos de su dispositivo móvil y/o web a partir del cual se acceda al servicio:
                            <br>
                            <br>
                            <ul>
                                <li>
                                    Ubicación.
                                </li>
                                <li>
                                    Aplicación de teléfono.
                                </li>
                                <li>
                                    Almacenamiento del dispositivo.
                                </li>
                                <li>
                                    Recepción de notificaciones.
                                </li>
                            </ul>
                        </font></font>
                        </span>
                        <br>
                        <br>
                        <span class="modaltitle-termscond">
                        <font face="Times New Roman, serif"><font size="1" style="font-size: 12pt">
                            Transferencia de sus datos personales.
                        </font></font>
                        </span>
                        <br>
                        <br>
                        <span class="modaltext-contentTermsCond">
                        <font face="Times New Roman, serif"><font size="1" style="font-size: 12pt">
                            Sus datos personales de identificación, contacto, académicos y laborales, podrán ser
                            transferidos a las siguientes personas físicas o morales, sin que legalmente se requiera su
                            consentimiento:
                            <br>
                            <br>
                            <ul>
                                <li>
                                    Autoridades a las que debamos hacerlo en virtud de una ley, con la finalidad de
                                    cumplir con la normatividad correspondiente.
                                </li>
                            </ul>
                        </font></font>
                        </span>
                        <br>
                        <br>
                        <span class="modaltitle-termscond">
                        <font face="Times New Roman, serif"><font size="1" style="font-size: 12pt">
                            Medidas de seguridad para proteger su información personal.
                        </font></font>
                        </span>
                        <br>
                        <br>
                        <span class="modaltext-contentTermsCond">
                        <font face="Times New Roman, serif"><font size="1" style="font-size: 12pt">
                            Su información personal será resguardada bajo estricta confidencialidad, y para prevenir
                            razonablemente el uso o divulgación indebida de la misma, hemos implementado medidas de
                            seguridad físicas, técnicas y administrativas de conformidad con la normatividad aplicable.
                            En particular, contamos con un líder de privacidad, la presente política de privacidad,
                            cursos de capacitación a nuestros empleados, acceso restringido a información personal sólo
                            a usuarios autorizados y cláusulas contractuales.
                            La seguridad de sus datos es prioridad para nosotros, sin embargo, los medios de
                            almacenamiento digitales o internet no son 100% seguros, por lo cual no podemos garantizar
                            la total seguridad de su información.
                        </font></font>
                        </span>
                        <br>
                        <br>
                        <span class="modaltitle-termscond">
                        <font face="Times New Roman, serif"><font size="1" style="font-size: 12pt">
                            Derechos ARCO y revocación de su consentimiento.
                        </font></font>
                        </span>
                        <br>
                        <br>
                        <span class="modaltext-contentTermsCond">
                        <font face="Times New Roman, serif"><font size="1" style="font-size: 12pt">
                            En los términos permitidos por la normatividad aplicable, es su derecho conocer qué datos
                            personales tenemos de usted, para qué los utilizamos y las condiciones del uso que les damos
                            (Acceso). Asimismo, es su derecho solicitar la corrección de su información personal en caso
                            de que esté desactualizada, sea inexacta o incompleta (Rectificación); que la eliminemos de
                            nuestros registros o bases de datos cuando considere que la misma no está siendo utilizada
                            conforme a las disposiciones previstas en la normatividad aplicable (Cancelación); y como
                            oponerse al uso de sus datos personales para fines específicos (Oposición). Estos derechos
                            se conocen como derechos ARCO.

                            <br>
                            <br>
                            Asimismo, usted tiene el derecho de revocar en cualquier momento el consentimiento que en su
                            caso nos haya otorgado para el tratamiento de sus datos personales, salvo que no sea
                            procedente en términos de la normatividad aplicable, lo cual haríamos de su conocimiento en
                            nuestra respuesta a su solicitud de revocación.
                            <br>
                            <br>
                            Para conocer el procedimiento, requisitos y plazos para el ejercicio de los derechos ARCO,
                            así como del derecho de revocación, le agradeceremos se ponga en contacto con nuestro líder
                            de privacidad a la mencionada dirección de correo electrónico. Si desea ejercer alguno o
                            algunos de estos derechos, deberá presentar la solicitud respectiva a nuestro líder de
                            privacidad vía correo electrónico, quien dará trámite a la misma y atenderá cualquier
                            comentario, duda o queja que pudiera tener respecto al tratamiento de su información
                            personal.
                            <br>
                            <br>
                            Nuestro líder de privacidad velará en todo momento por la protección de su información
                            personal en cumplimiento con la normatividad aplicable. Asimismo, le informamos que el
                            Instituto Federal de Acceso a la Información y Protección de Datos es la autoridad encargada
                            de vigilar por la debida observancia de las disposiciones legales en materia de protección
                            de datos personales.
                        </font></font>
                        </span>
                        <br>
                        <br>
                        <span class="modaltitle-termscond">
                        <font face="Times New Roman, serif"><font size="1" style="font-size: 12pt">
                            Formas en que puede limitar el uso o divulgación de la información personal.
                        </font></font>
                        </span>
                        <br>
                        <br>
                        <span class="modaltext-contentTermsCond">
                        <font face="Times New Roman, serif"><font size="1" style="font-size: 12pt">
                            Adicionalmente al procedimiento contemplado en la sección anterior, usted puede limitar el
                            uso o divulgación de su información personal, enviando un correo electrónico a nuestro líder
                            de privacidad a la dirección arriba indicada, solicitando su inscripción al “Listado de
                            Exclusión Publicitaria”, con el objeto de asegurarse que su información no será utilizada
                            para fines distintos a los estipulados en este aviso de privacidad.
                        </font></font>
                        </span>
                        <br>
                        <br>
                        <span class="modaltitle-termscond">
                        <font face="Times New Roman, serif"><font size="1" style="font-size: 12pt">
                            Cambios al aviso de privacidad.
                        </font></font>
                        </span>
                        <br>
                        <br>
                        <span class="modaltext-contentTermsCond">
                        <font face="Times New Roman, serif"><font size="1" style="font-size: 12pt">
                            Treembo® podrá modificar y/o actualizar el presente aviso de privacidad, informando
                            sobre cualquier modificación o actualización a través de nuestra página de internet:
                            <a target="_blank" href="https://treembo.com/">https://treembo.com/</a>
                        </font></font>
                        </span>
                        <br>
                        <br>
                        <span class="modaltitle-termscond">
                        <font face="Times New Roman, serif"><font size="1" style="font-size: 12pt">
                            Consentimiento.
                        </font></font>
                        </span>
                        <br>
                        <br>
                        <span class="modaltext-contentTermsCond">
                        <font face="Times New Roman, serif"><font size="1" style="font-size: 12pt">
                            Por este medio y al utilizar los servicios de Treembo otorgo mi consentimiento para que mis
                            datos personales sean tratados de conformidad con los términos del presente aviso de
                            privacidad.
                        </font></font>
                        </span>
                        <br>
                        <br>


                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</body>
</html>`;