import { useState, useEffect } from 'react';
import { Box, Container, Grid, Typography, Button, Input } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import TreemboIcon from 'components/treembo-icon';
import colors from 'theme/colors';
import imgSuffix from 'common/image-suffix';
import { WhatsApp } from '@mui/icons-material';
import constants from 'common/constants';
import TiktokPixel from 'utils/tiktok_pixel';

const PaymentSuccess = (props) => {
    const navigate = useNavigate()
    const [product, set_product] = useState(null)
    const [quantity, set_quantity] = useState(null)

    const getProduct = async () => {
        TiktokPixel.pageView();
        try {
            let aux = JSON.parse(localStorage.getItem("payment_product"));
            if (aux) {
                console.log('prod: ', aux)
                set_product(aux.product)
                set_quantity(aux.quantity)
                TiktokPixel.track('PlaceAnOrder',{
                    content_id: aux.uid,
                    content_name: aux.name,
                    content_type: "product",
                    value: aux.price,
                    currency: 'mxn',
                    quantity: aux.quantity
                })
            }
        } catch (error) {

        }
    }

    useEffect(() => {
        getProduct()
        return () => {
            console.log('REMOVE PRODUCT: ')
            if(localStorage.getItem("payment_product")){
                localStorage.removeItem("payment_product")
            }
        }
    }, [])

    const openContact = async (type) => {
        try {
            if (type === "whatsapp") {
                window.open(
                    "https://wa.me/523329504314?text=" +
                    `Hola, quisiera darle seguimiento a mi compra de ${product.name}: ${constants.URL.home}product/${product.uid}`
                )
            } else if (type === "phone") {
                window.open(
                    "tel:+523329504314"
                )
            }
        } catch (error) {
            console.log("CONTACT_ERROR: ", error)
        }
    }

    return (
        <Container maxWidth="sm" sx={{ display: "flex", flexDirection: "column", paddingBottom: "40px" }}>
            <TreemboIcon icon="treembo" size={80} color={colors.primary_green} style={{ marginBottom: "6px", marginTop: "32px" }} />
            <Box sx={{ width: "100%", position: "sticky", top: "8rem", backgroundColor: "white", zIndex: 5000, paddingTop: "16px" }}>
                <Typography variant='title3'>
                    ¡Gracias por tu compra!
                </Typography>
                <Typography variant='body2'>
                    Nuestro equipo ha recibido tu pedido con éxito, recibirás seguimiento de tu compra y envío por el correo electrónico que proporcionaste en la información de pago.
                </Typography>
            </Box>
            {product ? <Grid onClick={() => navigate(`/product/${product.uid}`)} container maxWidth="sm" sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignSelf: "center", marginTop: "16px", backgroundColor: "white" }}>
                <div>
                    <Grid item sx={{ display: "flex", flexDirection: "row", backgroundColor: "white", minHeight: "100px", marginTop: "4px", justifyContent: "center", alignItems: "center", cursor: "pointer", marginBottom: "4px" }}>
                        <Grid item xs={3} sm={2} md={2} sx={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", cursor: "pointer", borderRadius: "8px", overflow: "hidden", position: "relative" }}>
                            {product.images ?
                                <img
                                    alt="img"
                                    src={product.images[0] + imgSuffix.md}
                                    style={{ display: "flex", width: "100%", objectFit: "cover", height: "5.4rem", aspectRatio: "1:1" }}
                                />
                                : (product.legacy_images ?
                                    <img
                                        alt="img"
                                        src={product.legacy_images[0] + "480x480"}
                                        style={{ display: "flex", width: "100%", objectFit: "cover", height: "5.4rem", aspectRatio: "1:1" }}
                                    />
                                    : "")}
                        </Grid>
                        <Grid item xs={9} sm={10} md={10} sx={{ marginLeft: "16px", cursor: "pointer" }}>
                            <Typography variant="title8" sx={{ display: '-webkit-box', overflow: 'hidden', WebkitBoxOrient: 'vertical', WebkitLineClamp: 1 }}>{product.name}</Typography>
                            <Typography variant="body2" sx={{ display: '-webkit-box', overflow: 'hidden', WebkitBoxOrient: 'vertical', WebkitLineClamp: 2 }}>{product.description}</Typography>
                            {product.price && product.price != 0 && product.price != "" && product.price != null ?
                                <Typography variant="title8">{quantity ? `${quantity} x ` : ''}${product.price.toLocaleString("en-US")} {product.currency}</Typography>
                                : null}
                            <Typography variant="body2" sx={{ color: "gray", display: '-webkit-box', overflow: 'hidden', WebkitBoxOrient: 'vertical', WebkitLineClamp: 1 }}>{product.address && product.address.name != "" ? product.address.name : ""}</Typography>
                        </Grid>
                    </Grid>
                    <div style={{ backgroundColor: colors.light_gray, height: "1px", width: "100%" }} />
                </div>
            </Grid> : <Box style={{marginTop: "32px"}}>
                <Typography>Producto no disponible</Typography>
            </Box>
            }
            <Typography variant='body2' style={{ marginTop: "32px" }}>
                Para cualquier aclaración o seguimiento, te invitamos a contactar a nuestro equipo por nuestros canales oficiales de comunicación o redes sociales.
            </Typography>
            <Box style={{ marginTop: "32px", display: "flex", alignItems: "center", flexDirection: "column" }}>
                <Button variant="contained" sx={{ display: "flex", flexDirection: "row", backgroundColor: colors.primary_green, boxShadow: 3 }} onClick={() => openContact("whatsapp")}>
                    <WhatsApp fontSize="small" sx={{ color: "white" }} />
                    <Typography variant="body3" sx={{ color: "white", marginLeft: "8px" }}>
                        WhatsApp
                    </Typography>
                </Button>
                <Button onClick={() => navigate("/")} style={{ marginTop: "16px" }}>
                    Regresar a inicio
                </Button>
            </Box>
        </Container>
    )
}

PaymentSuccess.layout = "L1";
export default PaymentSuccess;