import { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { query, collection, orderBy, doc, getDoc, limit, where, getDocs } from "firebase/firestore";
import { db, functions } from 'services/firebase/firebaseConfig';
import { getAnalytics, logEvent } from 'firebase/analytics'
import constants from 'common/constants';
import { Box, Button, Container, Grid, Typography, Skeleton } from '@mui/material'
import sx from './home-intro-sx'
import imgSuffix from 'common/image-suffix';
import colors from "theme/colors";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Verified, ChevronRight, WhatsApp } from '@mui/icons-material';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import algoliasearch from 'algoliasearch';
import { Functions } from 'common/functions';
import { httpsCallable } from 'firebase/functions';
import Discount from "assets/svgs/product_insignia/discount.svg"
import FreeShipping from "assets/svgs/product_insignia/free_shipping.svg"
import MSI from "assets/svgs/product_insignia/msi.svg"
import Offer from "assets/svgs/product_insignia/offer.svg"
import SameDayShipping from "assets/svgs/product_insignia/same_day_shipping.svg"
import percentageDifference from 'utils/percentageDifference';

const client = algoliasearch(constants.algolia.appId, constants.algolia.searchKey)
const products_index = client.initIndex('firebase_products_createdAt_desc');
const productsStores_index = client.initIndex('firebase_products_stores');

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const cintilloVideo = require('../../../../assets/images/CintilloLoop.mp4');

export default function HomeTC(props) {
	const navigate = useNavigate()
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('md'));
	const [loading, setLoading] = useState(true);
	const [banners, setBanners] = useState([]);
	const [loadingBanners, setLoadingBanners] = useState(true);
	const [activeStep, setActiveStep] = useState(0);
	const [maxSteps, setmaxSteps] = useState(0);
	const [stores, setStores] = useState([]);
	const [loadingStores, setLoadingStores] = useState(true);
	const [cropCategories, setCropCategories] = useState([]);
	const [loadingCropCategories, setLoadingCropCategories] = useState(true);
	const [recentProducts, setRecentProducts] = useState([]);
	const [loadingRecentProducts, setLoadingRecentProducts] = useState(true);
	const [bestDeals, setBestDeals] = useState([]);
	const [loadingBestDeals, setLoadingBestDeals] = useState(true);
	const [carousels, setCarousels] = useState([]);
	const [loadingCarousels, setLoadingCarousels] = useState(true);

	useEffect(() => {
		const analytics = getAnalytics();
		logEvent(analytics, 'screen_view', {
			firebase_screen: "Insumos_Web", 
			firebase_screen_class: "Insumos_Web"
		});
		window.scrollTo(0, 0)
		getBanners()
		getStores()
		getCropCategories()
		getRecentProducts()
		getBestDeals()
		getCarousels()
	},[])

	const getBanners = async () => {
		try{
			const collRef = collection(db, constants.mode + "banners");
			const docsRef = query(collRef, orderBy("order", "asc"));
			let docSnap = await getDocs(docsRef);
			if(docSnap.docs.length > 0){
				var newBanners = []
				await docSnap.forEach((doc) => {
					const banner = {
						uid: doc.id,
						...doc.data(),
					}
					newBanners.push(banner)
				});
				setBanners(newBanners)
				setmaxSteps(newBanners.length)
				setLoadingBanners(false)
			}else{
				setLoadingBanners(false)
			}
		}catch(error){
			console.log("BANNERS ERROR: ", error)
			setLoadingBanners(false)
		}
	}

	const getStores = async () => {
		try{
			const collRef = collection(db, constants.mode + "stores");
			const docsRef = query(collRef, orderBy("createdAt", "desc"));
			let docSnap = await getDocs(docsRef);
			if(docSnap.docs.length > 0){
				var newStores = []
				await docSnap.forEach((doc) => {
					const store = {
						uid: doc.id,
						...doc.data(),
					}
					newStores.push(store)
				});
				newStores = Functions.shuffleArray(newStores)
				let index = newStores.findIndex(s => s.uid == constants.URL.treemboStore)
				let treemboStore = newStores[index]
				newStores.splice(index, 1)
				if(newStores.length > 10){
					newStores = newStores.slice(0,9)
				}
				newStores = [treemboStore,...newStores]
				setStores(newStores)
				setLoadingStores(false)
			}else{
				setLoadingStores(false)
			}
		}catch(error){
			console.log("STORES ERROR: ", error)
			setLoadingStores(false)
		}
	}

	const getCropCategories = async () => {
		try {
			const instance = httpsCallable(functions, 'categories-getCropCategories')
			let response = await instance({})
			let docSnap = response.data
			// console.log("crop categories: ", docSnap)
			if(docSnap.length > 0){
				var newCategories = []
				await docSnap.forEach((doc) => {
					const category = {
						uid: doc.id,
						...doc
					}
					newCategories.push(category)
				});
				setCropCategories(newCategories)
				setLoadingCropCategories(false)
			}else{
				setLoadingCropCategories(false)
			}
		} catch (error) {
			console.log("CROP CATEGORIES ERROR: ", error)
			setLoadingCropCategories(false)
		}
	}

	const getRecentProducts = async () => {
		try{
			let filters = `NOT store.label:"Treembo Store" AND ownerType:"store"`
			products_index.search("", {
				filters: filters,
				ruleContexts: "client"
			}).then(({ hits }) => {
				// console.log("Product hits: ", hits.length)
				let products = []
				hits.forEach((doc) => {
					products.push({
						...doc,
						uid: doc.objectID,
						price: parseFloat(doc.price),
					})
				});
				products = Functions.shuffleArray(products)
				setLoadingRecentProducts(false)
				setRecentProducts([])
				setRecentProducts(prev => [...products])
			});
		}catch(error){
			console.log("RECENT PRODUCTS ERROR: ", error)
			setLoadingRecentProducts(false)
		}
	}

	const getBestDeals = async () => {
		try{
			const collRef = collection(db, constants.mode + "products");
			const docsRef = query(collRef, orderBy("promotion", "desc"), where("promotion", "!=", ""), where("ownerType", "==", "store"), where("disabled","==",false), orderBy("stripe_payment", "desc"), orderBy("createdAt", "desc"), limit(15));
			let docSnap = await getDocs(docsRef);
			if(docSnap.docs.length > 0){
				var newProducts = []
				await docSnap.forEach((doc) => {
					const product = {
						uid: doc.id,
						...doc.data(),
						price: parseFloat(doc.data().price),
					}
					newProducts.push(product)
				});
				newProducts = Functions.shuffleArray(newProducts)
				setBestDeals(newProducts)
				setLoadingBestDeals(false)
			}else{
				setLoadingBestDeals(false)
			}
		}catch(error){
			console.log("BEST DEALS ERROR: ", error)
			setLoadingBestDeals(false)
		}
	}

	const getCarousels = async () => {
		try {
			const collRef = collection(db, constants.mode + "carousels");
			const docsRef = query(collRef, where("disabled", "==", false), where("isPrivate", "==", false), orderBy("order", "asc"));
			let docSnap = await getDocs(docsRef);
			if(docSnap.docs.length > 0){
				let carouselsResponse = []
				for(let item of docSnap.docs){
					let carouselProducts = []
					if(item.data().products && item.data().products.length > 15){
						carouselProducts = item.data().products.slice(0,15)
					}else{
						carouselProducts = item.data().products
					}
					let carousel = {
						...item.data(),
						uid: item.id,
						products: carouselProducts
					}
					// console.log("carousel: ", carousel)
					if(carousel.type == "filters"){
						
						carouselsResponse.push(await getCarouselProducts(carousel))
					}else{
						carouselsResponse.push(carousel)
					}
				}
				setCarousels(carouselsResponse);
				setLoadingCarousels(false);
			}else{
				setLoadingCarousels(false);
			}
		}catch(error){
			console.log("CAROUSELS ERROR: ", error);
			setLoadingCarousels(false);
		}
	}

	const getCarouselProducts = async (carousel) => {
		try{
            let carouselAux = {...carousel}
            let filters = ``
            Object.entries(carousel.categoryFilter).forEach(([key, value], index) => {
                filters = filters + `cropCategories.${key}:"${value}" AND `
            });
            filters = filters + `ownerType:"store"`
            let response = await productsStores_index.search("", {
                filters: filters,
                ruleContexts: "client"
            })
            var products = [...response.hits.slice(0,15)]
            // console.log("products: ", products)
            return carouselAux = {
                ...carouselAux,
                products
            }
        }catch(error){
            console.log("Error getting products algolia: ", error)
        }
	}

	const getSingleStore = async (uid) => {
		try {
			const docRef = doc(db, "store", uid);
			const docSnap = await getDoc(docRef);
			if (docSnap.exists()) {
				navigate(`/store/${docSnap.data().username}`)
			} else {
				// docSnap.data() will be undefined in this case
				console.log("No such document!");
			}
		} catch (error) {
			console.log("No such document!");
		}
	}

	useEffect(() => {
		if(!loadingBanners && !loadingStores && !loadingCropCategories && !loadingRecentProducts && !loadingBestDeals){
			setLoading(false)
		}
	},[loadingBanners, loadingStores, loadingCropCategories, loadingRecentProducts, loadingBestDeals])

	const shuffleArray = (array) => {
		for (var i = array.length - 1; i > 0; i--) { 
			var j = Math.floor(Math.random() * (i + 1));
			var temp = array[i];
			array[i] = array[j];
			array[j] = temp;
		}
		return array;
	}

	const openBanner = (type, referenceId, referenceUID, url) => {
        if (type == "store-product") {
			navigate(`/product/${referenceUID}`)
        } else if (type == "store") {
			getSingleStore(referenceUID)
        } else if (url != ""){
			window.open(url)
        }
	};

	const openTreeCommerce = (type, params) => {
		sessionStorage.removeItem("products_persist")
		if(type == "offers" || type == "recent"){
			navigate(`/products?filter=${params}`,{
                state:{
                    type,
                }
            })
		}else if(type == "category"){
			navigate(`/products?category=${params}`,{
                state:{
                    type,
                }
            })
		}else{
			navigate(`/products`)
		}
	};

	const openCarousel = (carousel) => {
		let uid = carousel.uid
		if(carousel.type == "manual"){
			navigate(`/products?carousel=${uid}`)
		}else if(carousel.type == "filters"){
			navigate(`/products?carousel=${uid}`)
		}
	};

	const openOfficialStores = () => {
		navigate("/stores")
	};

	const openProduct = (referenceUID) => {
		navigate(`/product/${referenceUID}`)
	};

	const openStore = (referenceUID) => {
		navigate(`/store/${referenceUID}`)
	};

	const openTreemboProducts = () => {
		navigate(`store/treembo_store/products`)
	};

	const handleStepChange = (step) => {
		setActiveStep(step);
	};

	const returnCategory = (category) => {
        switch(category){
            case "agrochemicals":
                return "Agroquímicos"
            case "clothes_and_accessories":
                return "Ropa y accesorios"
            case "crops":
                return "Cultivos"
            case "energy": 
                return "Energía"
            case "fertilizers":
                return "Fertilizantes"
            case "infrastructure_and_terrain":
                return "Infraestructura y terrenos"
            case "livestock_inputs": 
                return "Insumos ganaderos"
            case "materials_and_tools":
                return "Materiales y herramientas"
            case "seeds":
                return "Semillas"
            case "services":
                return "Servicios"
            case "technology": 
                return "Tecnología"
            case "transport_and_machinery":
                return "Transporte y maquinaria"
            default:
                return ""
        }
    }

	const _renderStoreCard = (store, i) => {
		return 	<Grid sx={sx.storeCard} key={i} onClick={()=>openStore(store.username)}>
					<Box sx={{ width: "48px", height: "48px", borderRadius: "24px", overflow: "hidden", backgroundColor: store.backgroundColor ? "#" + store.backgroundColor : "#1FADC1" }}>
						<img
							alt="img"
							src={store.logo + imgSuffix.md} 
							height={48} 
							width={48}
							style={sx.storeImage}
						/> 
					</Box>
					<Typography variant="body3" sx={sx.storeName}>{store.name}</Typography>
				</Grid>
	};

	const _renderCategoryCard = (category, i) => {
		return 	<Grid sx={sx.categoryCard} key={i} onClick={()=>openTreeCommerce("category", category.uid)}>
					{category.image ?
						<img
							alt="img"
							src={category.image + imgSuffix.md} 
							height={34} 
							width={34}
							style={{objectFit: "cover"}}
						/> 
					:<></>}
                	<Typography variant={'body4'} sx={sx.categoryText}>{category.es.charAt(0).toUpperCase() + category.es.slice(1)}</Typography>
				</Grid>
	};

	const _renderProductCard = (product, i) => {
		let priceEnabled = product.price && product.price != 0 && product.price != "" && product.price != null;
		let emptyInsignias = (!product.free_shipping && !product.msi && !product.promotion && !product.hasDiscount) ? true : false;
		return	<Grid item key={i} onClick={()=>openProduct(product.uid ? product.uid : product.id)} xs={sx.productCard}>
					<Box sx={sx.productImageContainer}>
						{product.images ?
							<img
								alt="img"
								src={product.images[0] + imgSuffix.md} 
								height={112} 
								width={112}
								style={{objectFit: "cover"}}
							/> 
						: (product.legacy_images ? 
							<img
								alt="img"
								src={product.legacy_images[0] + "480x480"} 
								height={112} 
								width={112}
								style={{objectFit: "cover"}}
							/> 
						: "")}
					</Box>
					<Grid item sx={sx.productInfoContainer}>
						<Grid item sx={{ display: "flex", flexDirection: "column" }}>
							{/* <Typography variant="caption1" sx={{ color: colors.primary_green }}>{product.categories && product.categories.lvl0 ? returnCategory(product.categories.lvl0).toUpperCase() : returnCategory(product.uniqueCategory).toUpperCase()}</Typography> */}
							<Typography variant="title9" sx={sx.productName}>{product.name}</Typography>
							<Typography variant="body4" sx={priceEnabled ? sx.productDesc : sx.productDesc2Lines}>{product.description}</Typography>
						</Grid>
						{product.hasDiscount && product.hasDiscount == true ?
							<Grid item sx={{ display: "flex", flexDirection: "row", alignItems: "flex-end" }}>
								<Typography variant="caption1" sx={{ color: colors.medium_gray, textDecorationLine: "line-through" }}>${product.previousPrice ? product.previousPrice.toLocaleString("en-US") : 0}&nbsp;</Typography>
							</Grid> 
						:null}
						{priceEnabled ?
							<Grid item sx={{ display: "flex", flexDirection: "row", alignItems: "center",}}>
								<Typography variant="title8">${product.price.toLocaleString("en-US")}&nbsp;</Typography>
								{product.discountPercentageVisible && product.hasDiscount && product.hasDiscount == true && product.previousPrice && parseFloat(product.previousPrice) > 0 ? 
									<Box style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
										<Typography variant='body3' style={{color: colors.primary_green, fontSize: "10px"}}>
											%{percentageDifference(product.previousPrice, product.price)} OFF
										</Typography>
									</Box>
								:<></>}
							</Grid> 
						:null}
						<Box style={{display: "flex", flexDirection: "row", flexWrap: "wrap"}}>
							{product.free_shipping ? <img 
								src={FreeShipping} 
								height={18} 
								width={18} 
								alt=''
								style={{marginRight: "4px"}}
							/> : <></>}
							{product.msi ? <img 
								src={MSI} 
								height={18} 
								width={18} 
								alt=''
								style={{marginRight: "4px"}}
							/> : <></>}
							{product.promotion ? <img 
								src={Offer} 
								height={18} 
								width={18} 
								alt=''
								style={{marginRight: "4px"}}
							/> : <></>}
							{product.discountPercentageVisible && product.hasDiscount && product.hasDiscount == true && product.previousPrice && parseFloat(product.previousPrice) > 0 ? <img 
								src={Discount} 
								height={18} 
								width={18} 
								alt=''
								style={{marginRight: "4px"}}
							/> : <></>}
							{emptyInsignias ? <WhatsApp
								color='primary'
								fontSize="18px"
							/> : <></>}
						</Box>
					</Grid>
				</Grid>
	}

	const loadedVideo = () => {
		document.getElementById('cintillo').play();
	}

	return (
		<Container maxWidth="xl" sx={isMobile ? sx.mainContainerMobile : sx.mainContainer}>
			{/* {loading ?
				<Box minHeight={250} style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
					<Typography>
						Cargando...
					</Typography>
				</Box>
			: */}
				<Grid maxWidth="sm" container sx={sx.mainGrid}>
					{loadingBanners ? 
						<Box sx={{ width: "100%", position: "relative", aspectRatio: "2.37 / 1", borderRadius: "16px", overflow: "hidden" }}>
							<Skeleton sx={{ width: "100%", height: "100%", transform: "unset" }}></Skeleton>
						</Box>
					:banners.length > 0 ?
						<Grid container sx={sx.carouselGrid}>
							<AutoPlaySwipeableViews
								axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
								index={activeStep}
								onChangeIndex={handleStepChange}
								enableMouseEvents
							>
								{banners.map((banner, index) => (
									<Grid item key={index} onClick={()=>openBanner(banner.referenceType, banner.referenceId, banner.referenceUID, banner.url)} sx={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", cursor: "pointer", borderRadius: "16px", overflow: "hidden", position: "relative"}}>
										<img
											alt="img"
											src={banner.image + imgSuffix.lg}
											style={{display: "flex", width: "100%", objectFit: "cover"}}
										/> 
									</Grid>
								))}
							</AutoPlaySwipeableViews>
						</Grid>
					:<></>}
					<video
						id="cintillo"
						loop
						autoPlay={true}
						muted={true}
						playsInline
						onLoad={loadedVideo}
						src={cintilloVideo}
						controls={false}
						style={{ flex: 1, width: "100%", marginTop: "16px", borderRadius: "8px" }}
					/>
					{loadingCropCategories ?
						<Grid container sx={sx.sectionGrid}>
							<Skeleton variant='text' width={"150px"} sx={{ fontSize: "1rem" }}/>
							<Grid container sx={{ display: 'flex', flexDirection: "column", overflow: "hidden" }}>
								<Grid sx={{ display: 'flex', flexDirection: "row" }}>
									{[0,1,2,3,4,5,6].map((i)=>{
										return <Skeleton key={i} variant='rounded' width={"86px"} height={"86px"} sx={{ marginTop: "4px", marginRight: "4px"}}/>
									})}
								</Grid>
							</Grid>
						</Grid>
					: cropCategories.length > 0 ?
						<Grid container sx={sx.sectionGrid}>
							<Typography variant={'title8'} color="twilight">Explora por cultivo</Typography>
							<Grid container sx={{ display: 'flex', flexDirection: "column", overflow: "auto" }}>
								<Grid sx={{ display: 'flex', flexDirection: "row" }}>
									{cropCategories.map((category, index)=>{
										return _renderCategoryCard(category, index)
									})}
								</Grid>  
							</Grid>
						</Grid>
					:<></>}
					{loadingCarousels ?
						<Grid container sx={sx.sectionGrid}>
							<Box sx={sx.titleRow}>
								<Skeleton variant='text' width={"150px"} sx={{ fontSize: "1rem" }}/>
								<Box sx={sx.moreRow}>
									<Skeleton variant='text' width={"80px"} sx={{ fontSize: "0.875rem" }}/>
								</Box>
							</Box>
							<Grid container sx={{ display: 'flex', flexDirection: "row", overflow: "hidden" }}>
								<Grid sx={{ display: 'flex', flexDirection: "row" }}>
									{[0,1,2,3,4].map((i)=>{
										return 	<Box sx={{ width: "112px", marginTop: "8px", marginRight: "12px" }}>
													<Skeleton variant='rounded' width={"112px"} height={"112px"} sx={{ }}/>
													<Skeleton variant='text' sx={{ fontSize: "0.875rem" }}/>
													<Skeleton variant='text' sx={{ fontSize: "0.875rem" }}/>
													<Skeleton variant='text' sx={{ fontSize: "0.85rem" }}/>
													<Skeleton variant='text' sx={{ fontSize: "0.75rem" }}/>
													<Skeleton variant='text' sx={{ fontSize: "1rem" }}/>
												</Box>
									})}
								</Grid> 
							</Grid>
						</Grid>
					:carousels.length > 0 ?
						<Grid container sx={sx.sectionGrid}>
							<Box sx={sx.titleRow}>
								<Typography variant={'title8'} color="twilight">{carousels[0].name}</Typography>
								{carousels[0].products.length >= 15 ?
									<Box sx={sx.moreRow} onClick={()=>openCarousel(carousels[0])}>
										<Typography variant='body3' color="primary">Ver más</Typography>
										<ChevronRight sx={{ fontSize: 16 }} color="primary"/>
									</Box>
								:<></>}
							</Box>
							<Grid container sx={{ display: 'flex', flexDirection: "row", overflow: "auto" }}>
								<Grid sx={{ display: 'flex', flexDirection: "row" }}>
									{carousels[0].products.map((product, index)=>{
										return _renderProductCard(product, index)
									})} 
								</Grid>
							</Grid>
						</Grid>
					:<></>}
					{loadingStores ?
						<Grid container sx={sx.sectionGrid}>
							<Box sx={sx.titleRow}>
								<Skeleton variant='text' width={"150px"} sx={{ fontSize: "1rem" }}/>
								<Box sx={sx.moreRow}>
									<Skeleton variant='text' width={"80px"} sx={{ fontSize: "0.875rem" }}/>
								</Box>
							</Box>
							<Grid container sx={{ display: 'flex', flexDirection: "column", overflow: "hidden" }}>
								<Grid sx={{ display: 'flex', flexDirection: "row" }}>
									{[0,1,2,3,4,5].map((i)=>{
										return 	<Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", width: "90px", marginTop: "8px", marginRight: "12px" }}>
													<Skeleton variant='circular' width={"48px"} height={"48px"} sx={{ }}/>
													<Skeleton variant='text' width={"90px"} sx={{ fontSize: "0.875rem" }}/>
												</Box>
									})}
								</Grid> 
							</Grid> 
						</Grid>
					:stores.length > 0 ?
						<Grid container sx={sx.sectionGrid}>
							<Box sx={sx.titleRow}>
								<Typography variant={'title8'} color="twilight">Tiendas oficiales</Typography>
								<Box sx={sx.moreRow} onClick={()=>openOfficialStores()}>
									<Typography variant='body3' color="primary">Ver más</Typography>
									<ChevronRight sx={{ fontSize: 16 }} color="primary"/>
								</Box>
							</Box>
							<Grid container sx={{ display: 'flex', flexDirection: "column", overflow: "auto" }}>
								<Grid sx={{ display: 'flex', flexDirection: "row", marginTop: "8px"}}>
									{stores.map((store, index)=>{
										return _renderStoreCard(store, index)
									})}
								</Grid>
							</Grid> 
						</Grid>
					:<></>}
					{loadingCarousels ?
						<Grid container sx={sx.sectionGrid}>
							<Box sx={sx.titleRow}>
								<Skeleton variant='text' width={"150px"} sx={{ fontSize: "1rem" }}/>
								<Box sx={sx.moreRow}>
									<Skeleton variant='text' width={"80px"} sx={{ fontSize: "0.875rem" }}/>
								</Box>
							</Box>
							<Grid container sx={{ display: 'flex', flexDirection: "row", overflow: "hidden" }}>
								<Grid sx={{ display: 'flex', flexDirection: "row" }}>
									{[0,1,2,3,4].map((i)=>{
										return 	<Box sx={{ width: "112px", marginTop: "8px", marginRight: "12px" }}>
													<Skeleton variant='rounded' width={"112px"} height={"112px"} sx={{ }}/>
													<Skeleton variant='text' sx={{ fontSize: "0.875rem" }}/>
													<Skeleton variant='text' sx={{ fontSize: "0.875rem" }}/>
													<Skeleton variant='text' sx={{ fontSize: "0.85rem" }}/>
													<Skeleton variant='text' sx={{ fontSize: "0.75rem" }}/>
													<Skeleton variant='text' sx={{ fontSize: "1rem" }}/>
												</Box>
									})}
								</Grid> 
							</Grid>
						</Grid>
					:carousels.length > 1 ?
						carousels.map((item, index)=>{
							if(index != 0 && item.products.length > 0){
								return	<Grid container sx={sx.sectionGrid}>
											<Box sx={sx.titleRow}>
												<Typography variant={'title8'} color="twilight">{item.name}</Typography>
												{item.products.length >= 15 ?
													<Box sx={sx.moreRow} onClick={()=>openCarousel(item)}>
														<Typography variant='body3' color="primary">Ver más</Typography>
														<ChevronRight sx={{ fontSize: 16 }} color="primary"/>
													</Box>
												:<></>}
											</Box>
											<Grid container sx={{ display: 'flex', flexDirection: "column", overflow: "auto" }}>
												<Grid sx={{ display: 'flex', flexDirection: "row", marginTop: "8px"}}>
													{item.products.map((product, index)=>{
														return _renderProductCard(product, index)
													})}
												</Grid>
											</Grid> 
										</Grid>
							}
						})
					:<></>}
				</Grid>
			{/* } */}
		</Container>
	)
}