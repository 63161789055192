import * as React from 'react';
import { Box, Container, Grid, Typography, Button } from '@mui/material'
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import colors from 'theme/colors'
import sx from './footer-sx'

import FooterPhone from "assets/images/footerImg.png"
import AppStore from "assets/images/footerAppstore.png"
import PlayStore from "assets/images/footerPlaystore.png"
import TikTok from "assets/svgs/tiktok.svg"
import { FacebookRounded, Instagram, LinkedIn, YouTube } from '@mui/icons-material';


export default function Footer() {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('md'));

	return (
		<Container maxWidth="xxl" sx={[sx.mainContainer, {background: isMobile ? "linear-gradient(#1AA66B, #3F7575)": "linear-gradient(to right, #1AA66B, #3F7575)"}]}>
			<Grid maxWidth={1440} container minHeight={478} sx={{justifyContent: "flex-end"}}>
				<Grid item sm={12} md={5}>
					<Box maxWidth={644} marginBottom="40px">
						<Box sx={{ marginTop: "40px"}}>
							<Typography variant={isMobile ? 'title5' : 'title2'} color="white">
								Inteligencia Artificial y Marketplace para el Agro
							</Typography>
						</Box>
						<Typography variant='body2' color="white" sx={{maxWidth: "538px", marginTop: "16px"}}>
							Presentamos <text style={{ fontWeight: "700" }}>Triny, el Asistente de Inteligencia Artificial</text>. Usa las sugerencias de Plagas y Enfermedades, Nutrición y Malezas para optimizar tu producción.
						</Typography>
						<Typography variant='body2' color="white" sx={{maxWidth: "538px", marginTop: "8px"}}>
							Explora las Tiendas Oficiales en Treembo. ¡Más de 600 soluciones disponibles para todas tus necesidades agrícolas!
						</Typography>
						<Typography variant='title7' color="white" sx={{marginTop: "8px"}}>
							¡Transformemos juntos el futuro del Agro!
						</Typography>
						<Box sx={{ marginTop: "32px" }}>
							<Typography variant='title6Bold' color="white">
								Descarga la app:
							</Typography>
						</Box>
						<Box sx={{display: "flex", flexDirection: "row", flexWrap: "wrap", marginTop: "16px"}}>
							<Button sx={{marginRight: "16px"}} onClick={()=>window.open("https://apps.apple.com/mx/app/treembo/id1523120507")} variant='image'>
								<img alt='' src={AppStore}/>
							</Button>
							<Button onClick={()=>window.open("https://play.google.com/store/apps/details?id=com.treembo&hl=en&gl=US")} variant='image'>
								<img alt='' src={PlayStore}/>
							</Button>
						</Box>
						<Box sx={{ marginTop: "32px" }}>
							<Typography variant='title6Bold' color="white">
								Síguenos
							</Typography>
						</Box>
						<Box sx={{ display: "flex", flexDirection: "row", marginTop: "16px" }}>
							<Box onClick={()=>window.open("https://www.instagram.com/treembo_/")} sx={{color: colors.main_white, marginRight: "12px"}}>
								<Instagram/>
							</Box>
							<Box onClick={()=>window.open("https://www.tiktok.com/@treembo?lang=es")} sx={{color: colors.main_white, marginRight: "12px"}}>
								<img 
									src={TikTok} 
									height={20} 
									width={20} 
									color="white"
									alt=''
								/>
							</Box>
							<Box onClick={()=>window.open("https://www.facebook.com/treembomx/")} sx={{color: colors.main_white, marginRight: "12px"}}>
								<FacebookRounded/>
							</Box>
							<Box onClick={()=>window.open("https://mx.linkedin.com/company/treembo-mx")} sx={{color: colors.main_white, marginRight: "12px"}}>
								<LinkedIn/> 
							</Box>
							<Box onClick={()=>window.open("https://www.youtube.com/channel/UCtJrAOZYtYRoQHEMP2XKQcw/videos")} sx={{color: colors.main_white}}>
								<YouTube/>
							</Box>
						</Box>
					</Box>
				</Grid>
				<Grid item sm={12} md={6} position="relative" sx={{minHeight: 260, display: "flex", flexDirection: "column", justifyContent: "flex-end" }}>
					<Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-end" }}>
						<img 
							src={FooterPhone}
							style={{width: "90%", backgroundColor: "transparent" }}
							alt=''
						/>
					</Box>
				</Grid>
			</Grid>
		</Container>
	)
}