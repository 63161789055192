import ContactForm from "components/contact-form/contact-form";
import Commerce from "./sections/commerce/commerce";
import Connect from "./sections/connect/connect";
import Footer from "./sections/footer/footer";
import Fresh from "./sections/fresh/fresh";
import HomeIntro from "./sections/home-intro/home-intro.screen";
import Information from "./sections/information/information";
import News from "./sections/news/news";
import Questions from "./sections/questions/questions";
import Testimonials from "./sections/testimonials/testimonials";
import { useContext, useEffect, useState } from "react";
import { LandingContext } from "contexts/landingContext";
import TreemboAppBar from "components/appbar/treembo-appbar";
import TiktokPixel from "utils/tiktok_pixel";

export default function Home(props) {
    // eslint-disable-next-line no-unused-vars
    const [state, dispatch] = useContext(LandingContext);
	const [openContact, setOpenContact] = useState(false);

	useEffect(() => {
        TiktokPixel.pageView();
		dispatch({
			type: 'SET_OPEN_FORM',
			payload: ()=>handleClickOpen()
		});
		dispatch({
			type: 'SET_CLOSE_FORM',
			payload: ()=>handleClose()
		});
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

    const handleClickOpen = () => {
		setOpenContact(true);
	};

	const handleClose = () => {
		setOpenContact(false);
	};
	return <>
        <section id="home">
            <HomeIntro/>
        </section>
        <Footer/>
        <ContactForm 
            handleClickOpen={handleClickOpen} 
            handleClose={handleClose} 
            openContact={openContact}
        />
    </>
}