import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import colors from 'theme/colors';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

export default function BannersAppbar(props) {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

	return (
		<AppBar position="sticky">
			<Toolbar sx={{ backgroundColor: colors.primary_green, paddingLeft: 0, paddingRight: 0, display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
				<Button onClick={()=>window.location.href = "/"} variant="contained" sx={{backgroundColor: colors.main_white, color: colors.primary_green, marginLeft: isMobile ? "16px" : 0}}>
					Ir a la Página Principal
				</Button>
			</Toolbar>
		</AppBar>
	)
}