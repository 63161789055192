import TreemboIcon from "components/treembo-icon";
import colors from "theme/colors";

const Maintenance = () => {
    return <div style={{height: window.innerHeight, width: window.innerWidth, display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column"}}>
        <TreemboIcon 
            icon="treembo" 
            size={82} 
            color={colors.primary_green} 
            style={{ marginBottom: "6px" }}
        />
        <span style={{fontSize: "24px", fontFamily: "baloo", paddingLeft: "32px", paddingRight: "32px", textAlign: "center", color: colors.primary_green}}>
            ¡Estamos trabajando para mejorar tu experiencia!
        </span>
        <span style={{marginTop: "32px", paddingLeft: "32px", paddingRight: "32px"}}>
            Lamentamos las molestias, pero nuestro servicio está temporalmente fuera de línea por mantenimiento. Estamos realizando mejoras importantes y esperamos restablecer el acceso lo antes posible.
        </span>
        
        <span style={{marginTop: "32px", paddingLeft: "32px", paddingRight: "32px", textAlign: "center", fontWeight: "bold"}}>
            Agradecemos tu paciencia y comprensión. ¡Vuelve a visitarnos pronto!
        </span>
    </div>
}

export default Maintenance;