let typography = {
	// TITLE
	title1: {
		fontFamily: "Roboto",
		fontSize: "3rem",
		fontWeight: "700"
	},
	title2: {
		fontFamily: "Roboto",
		fontSize: "2.5rem",
		fontWeight: "700"
	},
	title3: {
		fontFamily: "Roboto",
		fontSize: "2rem",
		fontWeight: "700"
	},
	title4: {
		fontFamily: "Roboto",
		fontSize: "1.75rem",
		fontWeight: "700"
	},
	title5: {
		fontFamily: "Roboto",
		fontSize: "1.5rem",
		weight: "700"
	},
	title6: {
		fontFamily: "Roboto",
		fontSize: "1.25rem",
		fontWeight: "700"
	},
	title7: {
		fontFamily: "Roboto",
		fontSize: "1.125rem",
		fontWeight: "700"
	},
	title8: {
		fontFamily: "Roboto",
		fontSize: "1rem",
		fontWeight: "700"
	},
	title9: {
		fontFamily: "Roboto",
		fontSize: "0.875rem",
		fontWeight: "700"
	},
	// BODY
	body1: {
		fontFamily: "Roboto",
		fontSize: "1.25rem",
	},
	body2: {
		fontFamily: "Roboto",
		fontSize: "1rem",
	},
	body3: {
		fontFamily: "Roboto",
		fontSize: "0.875rem",
	},
	body4: {
		fontFamily: "Roboto",
		fontSize: "0.85rem",
	},
	// CAPTION
	caption1: {
		fontFamily: "Roboto",
		fontSize: "0.75rem",
	},
	caption2: {
		fontFamily: "Roboto",
		fontSize: "0.563rem",
	},
	// BADGE
	badge1: {
		fontFamily: "Roboto",
		fontSize: "0.75rem",
		fontWeight: "bold"
	},
	badge2: {
		fontFamily: "Roboto",
		fontSize: "0.563rem",
		fontWeight: "bold"
	},
	// OVERLINE
	overline: {
		fontFamily: "Roboto",
		fontSize: "0.75rem",
		fontWeight: "bold"
	}
}

export default typography;