import { useState, useEffect } from 'react';
import { Box, Container, Grid, Typography, Button, Input } from '@mui/material';
import { Verified, ChevronLeft } from '@mui/icons-material';
import constants from 'common/constants';
import imgSuffix from 'common/image-suffix';
import { db } from 'services/firebase/firebaseConfig';
import { query, collection, orderBy, startAfter, limit, getDocs, where } from "firebase/firestore";
import { getAnalytics, logEvent } from 'firebase/analytics'
import colors from "theme/colors";
import { useNavigate } from 'react-router-dom';
import algoliasearch from 'algoliasearch';
import TiktokPixel from 'utils/tiktok_pixel';

const client = algoliasearch(constants.algolia.appId, constants.algolia.searchKey)
const index = client.initIndex('firebase_stores');

var last_search = "";

const Stores = (props) => {
    const navigate = useNavigate()
    const [stores, setStores] = useState([]);
    const [skipStores, setSkipStores] = useState(null);
    const limitStores = 15;
    const [loading, setLoading] = useState(true);
    const [loadingMore, setLoadingMore] = useState(false);
    const [allStoresDisplayed, setAllStoresDisplayed] = useState(false);
    const [categories, setCategories] = useState([]);
    const [categorySelected, setCategorySelected] = useState("");
    const [persist_scroll, set_persist_scroll] = useState(0);
    const [categoryChanged, setCategoryChanged] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [init, setInit] = useState(false)

    useEffect(() => {
        const analytics = getAnalytics();
		logEvent(analytics, 'screen_view', {
            firebase_screen: "ListaTiendas_Web", 
            firebase_screen_class: "ListaTiendas_Web"
		});
        logEvent(analytics, 'view_item_list', {
            item_list_id: "stores_Web",
            item_list_name: "Tiendas_Web"
        });
        TiktokPixel.pageView();
        let storageItem = sessionStorage.getItem("stores_persist")
        let stores_persist = JSON.parse(storageItem)
        if(stores_persist){
            set_persist_scroll(stores_persist.scrollY)
            setSkipStores(new Date(stores_persist.skipStores))
            setStores(stores_persist.stores)
            setLoading(stores_persist.loading)
            setLoadingMore(stores_persist.loadingMore)
            setAllStoresDisplayed(stores_persist.allStoresDisplayed)
            setCategories(stores_persist.categories)
            setCategorySelected(stores_persist.categorySelected)
            setSearchText(stores_persist.searchText)
            sessionStorage.removeItem("stores_persist")
        }else{
            getCategories()
            getStores()
        }
        setInit(true)
	},[])

    useEffect(()=>{
        if(init == true){
            if(persist_scroll && stores.length > 0){
                window.scrollTo(0, persist_scroll)
                set_persist_scroll(0)
            }else if (stores.length == 0 && categoryChanged){
                getStores()
            }else if(last_search != searchText && String(searchText).trim().length == 0 && stores.length == 0){
                // console.log("5")
                getStores()
            }
        }
    },[stores, persist_scroll, init])

    const getStores = async () => {
        setLoadingMore(true)
		try {
            let docSnap = null
            if(categorySelected != ""){
                //console.log("categoria: ", categorySelected)
                if(skipStores){
                    const collRef = collection(db, constants.mode + "stores")
                    const docsRef = query(collRef, orderBy("createdAt", "desc"), startAfter(skipStores), limit(limitStores), where("categories.lvl0", "array-contains", categorySelected))
                    docSnap = await getDocs(docsRef);
                }else{
                    const collRef = collection(db, constants.mode + "stores")
                    const docsRef = query(collRef, orderBy("createdAt", "desc"), limit(limitStores), where("categories.lvl0", "array-contains", categorySelected))
                    docSnap = await getDocs(docsRef);
                }
            }else{
                //console.log("categoria: ", categorySelected)
                if(skipStores){
                    const collRef = collection(db, constants.mode + "stores")
                    const docsRef = query(collRef, orderBy("createdAt", "desc"), startAfter(skipStores), limit(limitStores))
                    docSnap = await getDocs(docsRef);
                }else{
                    const collRef = collection(db, constants.mode + "stores")
                    const docsRef = query(collRef, orderBy("createdAt", "desc"), limit(limitStores))
                    docSnap = await getDocs(docsRef);
                }
            }
            if(docSnap.docs.length > 0){
                if(docSnap.docs.length < 15){
                    setAllStoresDisplayed(true)
                }
                setSkipStores(docSnap.docs[docSnap.docs.length-1].data().createdAt.toDate())
                var newStores = []
                await docSnap.forEach((doc) => {
                    const store = {
                        uid: doc.id,
                        ...doc.data()
                    }
                    newStores.push(store)
                });
                //console.log("tiendas: ", newStores)
                setStores([...stores, ...newStores])
                setLoading(false)
                setLoadingMore(false)
                setCategoryChanged(false)
            }else{
                setAllStoresDisplayed(true)
                setLoading(false)
                setLoadingMore(false)
                setCategoryChanged(false)
            }
        } catch (error) {
            setLoading(false)
            setLoadingMore(false)
            setCategoryChanged(false)
            console.log("tiendas: ", error)
        }
	}

    const getCategories = async () => {
        try {
            const collRef = collection(db, constants.mode + "commerce_categories")
            const docsRef = query(collRef)
            var docSnap = await getDocs(docsRef);
			if(docSnap.docs.length > 0){
                var categories = []
				await docSnap.docs.map(doc => {
                    const category = {
                        ...doc.data(),
                        id: doc.id
                    }
                    categories.push(category)
                })
                setCategories([...categories])
			}else{

            }
		} catch (error) {
			return false
		}
    }
    
    const moreStores = async () => {
        if(loadingMore == false){
            //console.log("SKIP: ", skipStores)
            getStores()
        }
    }

    const handleSubmit = async (event) => {
        setSearchText(event.target.value)
        if(last_search != event.target.value){
            if(String(event.target.value).trim().length > 2){
                last_search = event.target.value
                setLoading(true)
                handleSearch()
            }else if(String(event.target.value).trim().length == 0){
                //Necesito limpiar esto para la busqueda
                setSkipStores(null)
                setStores([])
                setAllStoresDisplayed(false)
                setLoading(true)
                //getProducts()
            }
        }
    }

    const handleSearch = () => {
		try {
			let filters = ``
			index.search(searchText, {
				filters: filters
			}).then(({ hits }) => {
				//console.log("Hits: ", hits.length)
				setLoading(false)
				setStores([])
				setStores(prev => [...hits])
                setAllStoresDisplayed(true)
			});
		} catch (error) {
			console.log("Error: ", error)
			setLoading(false)
		}
	}

    const openStore = async (store) => {
        let stores_persist = {
            stores,
            skipStores,
            loading,
            loadingMore,
            allStoresDisplayed,
            scrollY: window.scrollY,
            categories,
            categorySelected,
            searchText
        }
        sessionStorage.setItem("stores_persist", JSON.stringify(stores_persist))
        if(store.username){
            navigate(`/store/${store.username}`)
        }
    }

    const selectCategory = (categoryId) => {
        setSkipStores(null)
		setAllStoresDisplayed(false)
        setStores([])
        setSearchText("")
        setCategoryChanged(true)
        if(categorySelected == categoryId){
            setCategorySelected("")
        }else{
            setCategorySelected(categoryId)
        }
    }

    const returnCategory = (category) => {
        switch(category){
            case "agrochemicals":
                return "Agroquímicos"
            case "clothes_and_accessories":
                return "Ropa y accesorios"
            case "crops":
                return "Cultivos"
            case "energy": 
                return "Energía"
            case "fertilizers":
                return "Fertilizantes"
            case "infrastructure_and_terrain":
                return "Infraestructura y terrenos"
            case "livestock_inputs": 
                return "Insumos ganaderos"
            case "materials_and_tools":
                return "Materiales y herramientas"
            case "seeds":
                return "Semillas"
            case "services":
                return "Servicios"
            case "technology": 
                return "Tecnología"
            case "transport_and_machinery":
                return "Transporte y maquinaria"
        }
    }

	return (
		<Container maxWidth="sm" sx={{ display: "flex", flexDirection: "column", padding: "0px", paddingBottom: "40px", alignItems: "center", position: "relative"}}>
            <Box sx={{ display: "flex", flexDirection: "row", width: "100%", position: "sticky", top: "8rem", backgroundColor: "white", zIndex: 5000, paddingTop: "16px", alignItems: "center" }}>
                <ChevronLeft sx={{ fontSize: 36, marginRight: "16px" }} color="primary" onClick={()=>{navigate(-1)}}/>
                <Typography variant='title4'>
                    Tiendas oficiales
                </Typography>
            </Box>            
            {loading == true ?
                <Box minHeight={250} style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                    <Typography>
                        Cargando...
                    </Typography>
                </Box>
            :
                stores && stores.length > 0 ?
                    <Grid container maxWidth="sm" sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignSelf: "center", backgroundColor: "white", paddingLeft: "16px", paddingRight: "16px"}}>
                        {stores.map((store, index)=> {
                            if(store){
                                return  <>
                                            <Grid item onClick={()=>openStore(store)} sx={{ display: "flex", flexDirection: "row", backgroundColor: "white", height: "104px", marginTop: "4px", justifyContent: "center", alignItems: "center", cursor: "pointer" }}>
                                                <Grid item xs={3} sm={2} md={2} sx={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", backgroundColor: "white", cursor: "pointer" }}>
                                                    <Box sx={{ width: "80px", height: "80px", borderRadius: "40px", overflow: "hidden", backgroundColor: store.backgroundColor ? "#" + store.backgroundColor : "#1FADC1" }}>
                                                        <img
                                                            alt="img"
                                                            src={store.logo + imgSuffix.md} 
                                                            height={80} 
                                                            width={80}
                                                            style={{ borderRadius: "40px", objectFit: "contain"}}
                                                        /> 
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={9} sm={10} md={10} sx={{ display: "flex", flexDirection: "column", marginLeft: "16px", cursor: "pointer", backgroundColor: "white" }}> 
                                                    <Grid item sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                                        <Typography variant="title8">{store.name}</Typography>
                                                        <Verified sx={{ fontSize: "16px", marginLeft: "4px", color: "#1fadc1" }}/>
                                                    </Grid>
                                                    <Typography variant="body3" sx={{ display: '-webkit-box', overflow: 'hidden', WebkitBoxOrient: 'vertical', WebkitLineClamp: 2, color: colors.medium_gray }}>{store.description}</Typography>
                                                    <Typography variant="body3" sx={{ display: '-webkit-box', overflow: 'hidden', WebkitBoxOrient: 'vertical', WebkitLineClamp: 2, color: colors.medium_gray, marginTop: "4px" }}>{store.location}</Typography>
                                                </Grid>
                                            </Grid>
                                            {/* <Grid item sx={{ display: "flex", flexDirection: "row", backgroundColor: "white", cursor: "pointer" }}>
                                                <Grid item sx={{ display: "flex", flexDirection: "row", backgroundColor: "rgba(26, 166, 107, .1)", height: "28px", justifyContent: "center", alignItems: "center", marginBottom: "12px", marginLeft: "8px", cursor: "pointer", borderRadius: "14px" }}>
                                                    <Typography variant="body2" sx={{ paddingLeft: "16px", paddingRight: "16px", color: colors.primary_green }}>{returnCategory(store.categories.lvl0)}</Typography>
                                                </Grid>
                                            </Grid> */}
                                            <div style={{ backgroundColor: colors.light_gray, height: "1px", width: "100%" }}/>
                                        </>
                            }else{
                                return  <Box>
                                            <Typography>Tienda no disponible</Typography>
                                        </Box>
                            }
                        })}
                        {loadingMore == true ? 
                            <Box minHeight={250} style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                                <Typography>
                                    Cargando...
                                </Typography>
                            </Box>
                        :
                            !allStoresDisplayed ?
                                <Button sx={{ marginTop: "8px" }} onClick={()=>moreStores()}>
                                    <Typography variant='title8' sx={{ color: colors.primary_green }}>Ver más...</Typography>
                                </Button>
                            :null
                        }
                        
                    </Grid>
                :
                    loadingMore == true ? 
                        <Box minHeight={250} style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                            <Typography>
                                Cargando...
                            </Typography>
                        </Box>
                    :
                        <Box minHeight={250} style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                            <Typography>
                                No hay tiendas
                            </Typography>
                        </Box>
            }
        </Container>
	)
}

Stores.layout = "L1";
export default Stores;