let colors = {
	// PRIMARY UI COLORS
	main_white: "#FFFFFF",
	dusk: "#000000",
	error_red: "#D34D36",
	primary_green: "#1AA66B",
	active_green: "#2fb07a1a",
	dark_gray: "#333333",
	medium_gray: "#6D6D6D",
	medium_light_gray: "#CFCFCF",
	light_gray: "#F1F1F1",
	light_background: "#F0F0F0",
	// SECONDARY COLORS
	twilight: "#294B59",
}

export default colors;