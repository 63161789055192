/* eslint-disable no-unused-vars */
import { useState } from 'react';
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import {
	Dialog,
	Button,
	Typography,
	TextField,
	InputAdornment,
	FormGroup,
	FormControlLabel,
	Checkbox
} from '@mui/material';
import SupportAgentRoundedIcon from '@mui/icons-material/SupportAgentRounded';
import colors from 'theme/colors';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { ExpandMoreOutlined, CheckRounded, WhatsApp} from '@mui/icons-material';
import GoogleMaps from 'components/google-places-autocomplete';
import MUIDatePicker from 'components/date-picker';

import moment from "moment";

export default function ContactForm(props) {
	const [step, setStep] = useState(0)

	// FIRST FORM VALUES
	const [cropName, setCropName] = useState("")
	const [cropVariety, setCropVariety] = useState("")
	const [cropWeight, setCropWeight] = useState("")
	const [cropLocation, setCropLocation] = useState(null)
	const [harvestDate, setHarvestDate] = useState("")
	const [marketingMeans, setMarketingMeans] = useState("")
	// FIRST FORM ERRORS
	const [cropNameError, setCropNameError] = useState(false)
	const [cropVarietyError, setCropVarietyError] = useState(false)
	const [cropWeightError, setCropWeightError] = useState(false)
	const [cropLocationError, setCropLocationError] = useState(false)
	const [harvestDateError, setHarvestDateError] = useState(false)
	const [marketingMeansError, setMarketingMeansError] = useState(false)
	// SECOND FORM VALUES
	const [contactFirstName, setContactFirstName] = useState("")
	const [contactLastName, setContactLastName] = useState("")
	const [contactEmail, setContactEmail] = useState("")
	const [contactPhoneNumber, setContactPhoneNumber] = useState("")
	const [contactMessage, setContactMessage] = useState("")
	const [contactHour, setContactHour] = useState("")
	// SECOND FORM VALUES
	const [contactFirstNameError, setContactFirstNameError] = useState("")
	const [contactLastNameError, setContactLastNameError] = useState("")
	const [contactEmailError, setContactEmailError] = useState("")
	const [contactPhoneNumberError, setContactPhoneNumberError] = useState("")
	const [contactMessageError, setContactMessageError] = useState("")
	const [contactHourError, setContactHourError] = useState(0)

	const [agreeChecked, setAgreeChecked] = useState(false)
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

	const handleAgreeChange = (event) => {
		setAgreeChecked(event.target.checked);
	};

	const resetModal = () => {
		goToStep(0)
		props.handleClose()
	}

	const goToStep = (step) => {
		console.log("Going to step: ", step)
		switch (step) {
			case 0:
				setStep(0)
				break;
			case 1:
				setStep(1)
				break;
			case 2:
				setStep(2)
				break;
			case 3:
				setStep(3)
				break;
			default:
				setStep(0)
		}
	}

	const verifyFlow1 = () => {
        setCropNameError(false)
        setCropVarietyError(false)
		setCropWeightError(false)
        setCropLocationError(false)
        setHarvestDateError(false)
        setMarketingMeansError(false)
        console.log("cropName: ", cropName)
        console.log("cropVariety: ", cropVariety)
        console.log("cropWeight: ", cropWeight)
        console.log("cropLocation: ", cropLocation)
        console.log("harvestDate: ", harvestDate)
        console.log("marketingMeans: ", marketingMeans)
        let error = false;
        if(cropName.trim() === ""){
            error = true
            setCropNameError(true)
        }
        if(cropVariety.trim() === ""){
            error = true
            setCropVarietyError(true)
        }
        if(cropWeight.trim() === ""){
            error = true
            setCropWeightError(true)
        }
        if(cropLocation == null){
            error = true
            setCropLocationError(true)
        }else if(cropLocation.description.trim() === ""){
            error = true
            setCropLocationError(true)
        }
        if(marketingMeans.trim() === ""){
            error = true
            setMarketingMeansError(true)
        }
        if(error){
            console.log("ERROR")
        }else{
            goToStep(2)
        }
    }

    const verifyFlow2 = async () => {
        setContactFirstNameError(false)
        setContactLastNameError(false)
        setContactEmailError(false)
        setContactPhoneNumberError(false)
        setContactMessageError(false)
        setContactHourError(false)
        console.log("contactFirstName: ", contactFirstName)
        console.log("contactLastName: ", contactLastName)
        console.log("contactEmail: ", contactEmail)
        console.log("contactPhone: ", contactPhoneNumber)
        console.log("contactMessage: ", contactMessage)
        console.log("contactHour: ", contactHour)
        let error = false;
        if(contactFirstName.trim() === ""){
            error = "first_name"
            setContactFirstNameError(true)
        }
        if(contactLastName.trim() === ""){
            error = "last_name"
            setContactLastNameError(true)
        }
        if(contactEmail.trim() === ""){
            error = "email"
            setContactEmailError(true)
        }
        if(contactPhoneNumber.length < 10){
            error = "phone"
            setContactPhoneNumberError(true)
        }
        if(contactMessage.trim() === ""){
            error = "message"
            setContactMessageError(true)
        }
        if(parseInt(contactHour) < 8){
            error = "hour"
            setContactHourError(true)
        }
        if(error){
            console.log("ERROR: ", error)
        }else{
            try {
                let body = {
                    isSeller: true,
                    cropName,
                    cropVariety,
                    cropWeight,
                    cropLocation: cropLocation.description,
                    cropHarvestDate: moment(harvestDate).format('DD-MM-YYYY'),
                    cropMarketingMeans: marketingMeans,
                    cropProductReqs: "",
                    contactMessage,
                    contactHour,
                    contactFirstName,
                    contactLastName,
                    contactEmail,
                    contactPhoneNumber,
                    cropProductCategory: "",
                }
                console.log("SEND MESSAGE: ", body)
                // let response = await createNewDealAC(body)
                // console.log("RESPONSE: ", response)
                if(true){
                    goToStep(3)
                }
            } catch (e) {
                console.log(e)
            }
            console.log("SEND TO AC")
        }
    }

	const stepHeader = () => {
		return (
			<Box sx={{ display: "flex", flexDirection: "row", justifyContent: "center", marginTop: "16px", marginBottom: step === 0 ? "50px" : "16px" }}>
				{/* {step > 0 ? <Box sx={{ display: "flex", flexDirection: "row", position: "absolute", left: 0 }}>
					<Button onClick={() => { goToStep(step - 1) }} size='small' variant='image' sx={{ maxWidth: "24px", margin: 0, padding: 0, color: colors.medium_gray }}>
						<ChevronLeftRounded color='primary' size="large" />
					</Button>
				</Box> : ""} */}
				<WhatsApp color='primary' sx={{ marginRight: "8px" }} />
				<Typography variant='title8' color="primary">
					WhatsApp Oficial
				</Typography>
				<Box sx={{ display: "flex", flexDirection: "row", position: "absolute", right: 0 }}>
					<Button onClick={props.handleClose} size='small' variant='image' sx={{ maxWidth: "24px", margin: 0, padding: 0, color: colors.medium_gray }}>
						<ExpandMoreOutlined />
					</Button>
				</Box>
			</Box>
		)
	}

	const contactAdvisor = () => {
		try {
			window.open(
				"https://wa.me/523329504314?text=" + 
				`Hola, necesito contactar a un asesor Treembo.`
			)
		} catch (error) {
			console.log("CONTACT_ERROR: ", error)
		}
	}

	const step0 = () => {
		return (
			<>
				{stepHeader()}
				<Typography variant='title7' textAlign="center" sx={{ maxWidth: "100%" }}>
					Queremos ayudarte en lo que necesites
				</Typography>
				<Typography variant='body3' textAlign="center" sx={{ width: "100%" }}>
					Nuestros asesores especializados te estarán esperando.
				</Typography>
				<Button onClick={() => contactAdvisor()} sx={{ marginTop: "36px", width: "100%", borderRadius: "8px" }} variant="contained">
					Contactar Asesor Treembo
				</Button>
				{/* <Button onClick={() => goToStep(1)} sx={{ marginTop: "36px", width: "100%", borderRadius: "8px" }} variant="contained">
					Quiero vender mi cultivo
				</Button>
				<Button onClick={() => goToStep(2)} sx={{ marginTop: "12px", width: "100%", borderRadius: "8px" }} variant="contained">
					Quiero hacer mi tienda en línea
				</Button>
				<Button onClick={() => goToStep(2)} sx={{ marginTop: "12px", width: "100%", borderRadius: "8px" }} variant="contained">
					Quiero impulsar mi estrategia digital
				</Button>
				<Button onClick={() => goToStep(2)} sx={{ marginTop: "12px", width: "100%", borderRadius: "8px" }} variant="contained">
					Otro
				</Button> */}
			</>
		)
	}

	const step1 = () => {
		return (
			<>
				{stepHeader()}
				<Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", width: "100%", }}>
					<TextField
						label="Nombre del cultivo*"
						variant="outlined"
						fullWidth={true}
						placeholder="Escribe el nombre del cultivo"
						onChange={(e) => setCropName(e.target.value)}
						error={cropNameError}
						value={cropName}
						sx={{ marginBottom: "16px" }}
						size="small"
					/>
					<TextField
						label="Variedad*"
						variant="outlined"
						fullWidth={true}
						placeholder="Escribe la variedad del cultivo"
						onChange={(e) => setCropVariety(e.target.value)}
						error={cropVarietyError}
						value={cropVariety}
						sx={{ marginBottom: "16px" }}
						size="small"
					/>
					<TextField
						label="Peso total del cultivo*"
						variant="outlined"
						fullWidth={true}
						placeholder="Ingresa el peso total"
						InputProps={{
							endAdornment: <InputAdornment position="end"><Typography fontSize="14px">Toneladas</Typography></InputAdornment>,
						}}
						onChange={(e) => setCropWeight(e.target.value)}
						error={cropWeightError}
						value={cropWeight}
						sx={{ marginBottom: "16px" }}
						size="small"
					/>
					<GoogleMaps
						setCropLocation={setCropLocation}
						cropLocationError={cropLocationError}
						value={cropLocation}
					/>
					<MUIDatePicker
						label="Inicio de cosecha"
						placeholder="Fecha"
						setHarvestDate={setHarvestDate}
						harvestDateError={harvestDateError}
						value={harvestDate}
					/>
					<TextField
						label="Medios de comercialización*"
						variant="outlined"
						fullWidth={true}
						placeholder="Ej: Coyotes, mercado de abastos, etc."
						onChange={(e) => setMarketingMeans(e.target.value)}
						error={marketingMeansError}
						value={marketingMeans}
						sx={{ marginBottom: "0px" }}
						size="small"
					/>
					<Box width="100%" sx={{ marginBottom: "16px" }}>
						<Typography variant='caption1' color="medium_gray">
							* Campos obligatorios
						</Typography>
					</Box>
					<Button onClick={() => verifyFlow1()} variant='contained' sx={{ width: "211px", alignSelf: "center", marginBottom: "24px" }}>
						Continuar
					</Button>
				</Box>
			</>
		)
	}

	const step2 = () => {
		return (
			<>
				{stepHeader()}
				<Box sx={{ display: "flex", flexDirection: "row" }}>
					<TextField
						label="Nombre*"
						variant="outlined"
						fullWidth={true}
						placeholder="Escribe tu nombre"
						onChange={(e) => setContactFirstName(e.target.value)}
						value={contactFirstName}
						error={contactFirstNameError}
						sx={{ marginBottom: "16px", marginRight: "8px" }}
						size="small"
					/>
					<TextField
						label="Apellidos*"
						variant="outlined"
						fullWidth={true}
						placeholder="Escribe tus apellidos"
						onChange={(e) => setContactLastName(e.target.value)}
						value={contactLastName}
						error={contactLastNameError}
						sx={{ marginBottom: "16px", marginLeft: "8px" }}
						size="small"
					/>
				</Box>
				<TextField
					label="Correo electrónico*"
					variant="outlined"
					fullWidth={true}
					placeholder="Escribe tu correo electrónico"
					onChange={(e) => setContactEmail(e.target.value)}
					value={contactEmail}
					error={contactEmailError}
					sx={{ marginBottom: "16px" }}
					size="small"
				/>
				<TextField
					label="Teléfono*"
					variant="outlined"
					fullWidth={true}
					placeholder="Escribe tu número telefónico a 10 dígitos"
					onChange={(e) => setContactPhoneNumber(e.target.value)}
					value={contactPhoneNumber}
					error={contactPhoneNumberError}
					sx={{ marginBottom: "16px" }}
					size="small"
				/>
				<TextField
					label="¿Cómo podemos ayudarte?*"
					variant="outlined"
					multiline={true}
					fullWidth={true}
					placeholder="Escribe tu mensaje"
					minRows={2}
					maxRows={2}
					onChange={(e) => setContactMessage(e.target.value)}
					value={contactMessage}
					error={contactMessageError}
					sx={{ marginBottom: "0px" }}
					size="small"
				/>
				<Typography variant='caption1' sx={{ alignSelf: "flex-end", marginRight: "24px" }}>
					{contactMessage.length}/200
				</Typography>
				{/* <MUITimePicker
					setContactHour={setContactHour}
					value={contactHour}
					error={contactHourError}
				/> */}
				<FormGroup >
					<FormControlLabel
						className="personal-data-label"
						control={
							<Checkbox
								defaultChecked={false}
								style={{ marginRight: "1rem" }}
								onChange={handleAgreeChange}
								checked={agreeChecked}
							/>
						}
						label={<p style={{ fontSize: "0.75rem", margin: 0, padding: 0 }}>Acepto el tratamiento de mis datos personales</p>}
					/>
				</FormGroup>
				<Box width="100%" sx={{ marginBottom: "16px" }}>
					<Typography variant='caption1' color="medium_gray">
						* Campos obligatorios
					</Typography>
				</Box>
				<Button disabled={!agreeChecked} onClick={() => verifyFlow2()} variant="contained" className="continue-button-form">
					¡Quiero que me contacten!
				</Button>
			</>
		)
	}

	const step3 = () => {
		return (
			<>
				{stepHeader()}
				<Box sx={{display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: colors.primary_green, borderRadius: "40px", height: "80px", width: "80px", marginTop: "50px", marginBottom: "16px"}}>
                    <CheckRounded sx={{fontSize: 60, color: "white"}}/>
                </Box>
				<Typography variant='title6' color="primary">
					¡Hemos recibido tus datos!
				</Typography>
				<Typography variant='body3' sx={{marginBottom: "100px"}}>
					En breve nos estaremos poniendo en contacto contigo.
				</Typography>
				<Typography variant='caption1' sx={{marginBottom: "16px"}}>
					Estamos a tu lado para ayudarte en lo que necesites.
				</Typography>
                <Button onClick={()=>resetModal()} variant="contained" className="continue-button-form">
                    Ir a la Página Principal
                </Button>
			</>
		)
	}

	return (
		<>
			{/* <Fab variant="extended" color='primary' sx={{ textTransform: 'none' }} onClick={props.handleClickOpen}>
				<SupportAgentRoundedIcon sx={{ mr: 1 }} />
				¿Cómo podemos ayudarte?
			</Fab> */}
			<Dialog
				keepMounted={true}
				fullScreen={fullScreen}
				open={props.openContact}
				onClose={props.handleClose}
				aria-labelledby="responsive-dialog-title"
				sx={{
					"& .MuiDialog-container": {
						justifyContent: "flex-end",
						alignItems: "flex-end"
					}
				}}
				PaperProps={{
					sx: {
						m: 0,
						bottom: fullScreen ? 0 : 80,
						right: fullScreen ? 0 : 16,
						width: fullScreen ? window.innerWidth : 435,
						height: fullScreen ? 300 : 250,
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						paddingRight: fullScreen ? "16px" : "36px",
						paddingLeft: fullScreen ? "16px" : "36px",
						borderRadius: fullScreen ? "0px" : "16px",
						borderTopLeftRadius: "16px",
						borderTopRightRadius: "16px"
					}
				}}
			>
				{step === 0 ? step0() : ""}
				{step === 1 ? step1() : ""}
				{step === 2 ? step2() : ""}
				{step === 3 ? step3() : ""}
			</Dialog>
		</>
	);
}