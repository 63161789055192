import { useState, useEffect } from 'react';
import { Box, Container, Grid, Typography, Button, Input } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import TiktokPixel from 'utils/tiktok_pixel';
var __html = require('./privacyTerms.html.js');
var template = { __html: __html };

const PrivacyTerms = (props) => {
	const navigate = useNavigate()

	useEffect(() => {
		TiktokPixel.pageView();
	},[])

	return (
		<Container maxWidth="sm" sx={{ display: "flex", flexDirection: "column", paddingBottom: "40px" }}>
			{/* <Box sx={{width: "100%", position: "sticky", top: "8rem", backgroundColor: "white", zIndex: 5000, paddingTop: "16px"}}>
                <Typography variant='title3'>
                    Términos y condiciones
                </Typography>
            </Box> */}
			<div dangerouslySetInnerHTML={template} />
		</Container>
	)
}

PrivacyTerms.layout = "L1";
export default PrivacyTerms;