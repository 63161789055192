import { useState } from 'react';
import { Box, Button, Container, Grid, Paper, Typography, Dialog, Stepper, Step, StepLabel, TextField, InputAdornment, FormGroup, FormControlLabel, Checkbox } from '@mui/material'
import { ChevronLeftRounded } from '@mui/icons-material';
import colors from 'theme/colors';
import GoogleMaps from 'components/google-places-autocomplete';
import MUIDatePicker from 'components/date-picker';
import MUITimePicker from 'components/time-picker';
import { CheckRounded } from '@mui/icons-material';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const ProductForm = (props) => {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const product = props.product;

	const [step, setStep] = useState(0)
	const [contactWhats, setContactWhats] = useState(false)
	const [contactPhone, setContactPhone] = useState(false)
	const [harvestFlow, setHarvestFlow] = useState(false)
	const [flowType, setFlowType] = useState(null)
	const [finishedFlow, setFinishedFlow] = useState(false)

	// FIRST FORM VALUES
	const [cropName, setCropName] = useState("")
	const [cropVariety, setCropVariety] = useState("")
	const [cropWeight, setCropWeight] = useState("")
	const [cropLocation, setCropLocation] = useState(null)
	const [harvestDate, setHarvestDate] = useState("")
	const [marketingMeans, setMarketingMeans] = useState("")
	// FIRST FORM ERRORS
	const [cropNameError, setCropNameError] = useState(false)
	const [cropVarietyError, setCropVarietyError] = useState(false)
	const [cropWeightError, setCropWeightError] = useState(false)
	const [cropLocationError, setCropLocationError] = useState(false)
	const [harvestDateError, setHarvestDateError] = useState(false)
	const [marketingMeansError, setMarketingMeansError] = useState(false)
	// SECOND FORM VALUES
	const [contactFirstName, setContactFirstName] = useState("")
	const [contactLastName, setContactLastName] = useState("")
	const [contactEmail, setContactEmail] = useState("")
	const [contactPhoneNumber, setContactPhoneNumber] = useState("")
	const [contactMessage, setContactMessage] = useState("")
	const [contactHour, setContactHour] = useState("")
	// SECOND FORM VALUES
	const [contactFirstNameError, setContactFirstNameError] = useState("")
	const [contactLastNameError, setContactLastNameError] = useState("")
	const [contactEmailError, setContactEmailError] = useState("")
	const [contactPhoneNumberError, setContactPhoneNumberError] = useState("")
	const [contactMessageError, setContactMessageError] = useState("")
	const [contactHourError, setContactHourError] = useState(0)

	const [agreeChecked, setAgreeChecked] = useState(false)

	const handleAgreeChange = (event) => {
		setAgreeChecked(event.target.checked);
	};

	const goBack = () => {
		if (step > 0) {
			setStep(step - 1)
		} else {
			props.handleFormClose()
		}
	}

	const closeForm = () => {
		props.handleFormClose()
		setStep(0)
	}

	const renderStep1 = () => {
		return (
			<>
				<TextField
					label="Variedad*"
					variant="outlined"
					fullWidth={true}
					placeholder="Escribe la variedad del cultivo"
					onChange={(e) => setCropVariety(e.target.value)}
					error={cropVarietyError}
					value={cropVariety}
					sx={{ marginBottom: "16px" }}
					size="small"
				/>
				<TextField
					label="Peso total del cultivo*"
					variant="outlined"
					fullWidth={true}
					placeholder="Ingresa el peso total"
					InputProps={{
						endAdornment: <InputAdornment position="end"><Typography fontSize="14px">Toneladas</Typography></InputAdornment>,
					}}
					onChange={(e) => setCropWeight(e.target.value)}
					error={cropWeightError}
					value={cropWeight}
					sx={{ marginBottom: "16px" }}
					size="small"
				/>
				<GoogleMaps
					setCropLocation={setCropLocation}
					cropLocationError={cropLocationError}
					value={cropLocation}
				/>
				<MUIDatePicker
					label="Inicio de cosecha"
					placeholder="Fecha"
					setHarvestDate={setHarvestDate}
					harvestDateError={harvestDateError}
					value={harvestDate}
				/>
				<TextField
					label="Medios de comercialización*"
					variant="outlined"
					fullWidth={true}
					placeholder="Ej: Coyotes, mercado de abastos, etc."
					onChange={(e) => setMarketingMeans(e.target.value)}
					error={marketingMeansError}
					value={marketingMeans}
					sx={{ marginBottom: "0px" }}
					size="small"
				/>
				<Box width="100%" sx={{ marginBottom: "16px" }}>
					<Typography variant='caption1' color="medium_gray">
						* Campos obligatorios
					</Typography>
				</Box>
				<Button onClick={() => setStep(1)} variant='contained' sx={{ width: "211px", alignSelf: "center", marginBottom: "24px" }}>
					Continuar
				</Button>
			</>
		)
	}

	const renderStep2 = () => {
		return (
			<>
				<Box sx={{ display: "flex", flexDirection: "row", width: "100%" }}>
					<TextField
						label="Nombre*"
						variant="outlined"
						fullWidth={true}
						placeholder="Escribe tu nombre"
						onChange={(e) => setContactFirstName(e.target.value)}
						value={contactFirstName}
						error={contactFirstNameError}
						sx={{ marginBottom: "16px", marginRight: "8px" }}
						size="small"
					/>
					<TextField
						label="Apellidos*"
						variant="outlined"
						fullWidth={true}
						placeholder="Escribe tus apellidos"
						onChange={(e) => setContactLastName(e.target.value)}
						value={contactLastName}
						error={contactLastNameError}
						sx={{ marginBottom: "16px", marginLeft: "8px" }}
						size="small"
					/>
				</Box>
				<TextField
					label="Correo electrónico*"
					variant="outlined"
					fullWidth={true}
					placeholder="Escribe tu correo electrónico"
					onChange={(e) => setContactEmail(e.target.value)}
					value={contactEmail}
					error={contactEmailError}
					sx={{ marginBottom: "16px" }}
					size="small"
				/>
				<TextField
					label="Teléfono*"
					variant="outlined"
					fullWidth={true}
					placeholder="Escribe tu número telefónico a 10 dígitos"
					onChange={(e) => setContactPhoneNumber(e.target.value)}
					value={contactPhoneNumber}
					error={contactPhoneNumberError}
					sx={{ marginBottom: "16px" }}
					size="small"
				/>
				<TextField
					label="¿Cómo podemos ayudarte?*"
					variant="outlined"
					multiline={true}
					fullWidth={true}
					placeholder="Escribe tu mensaje"
					minRows={2}
					maxRows={2}
					onChange={(e) => setContactMessage(e.target.value)}
					value={contactMessage}
					error={contactMessageError}
					sx={{ marginBottom: "0px" }}
					size="small"
				/>
				<Typography variant='caption1' sx={{ alignSelf: "flex-end", marginRight: "24px" }}>
					{contactMessage.length}/200
				</Typography>
				{/* <MUITimePicker
					setContactHour={setContactHour}
					value={contactHour}
					error={contactHourError}
				/> */}
				<FormGroup sx={{ width: "100%" }}>
					<FormControlLabel
						className="personal-data-label"
						control={
							<Checkbox
								defaultChecked={false}
								style={{ marginRight: "1rem" }}
								onChange={handleAgreeChange}
								checked={agreeChecked}
							/>
						}
						label={<p style={{ fontSize: "0.75rem", margin: 0, padding: 0 }}>Acepto el tratamiento de mis datos personales</p>}
					/>
				</FormGroup>
				<Box width="100%" sx={{ marginBottom: "16px" }}>
					<Typography variant='caption1' color="medium_gray">
						* Campos obligatorios
					</Typography>
				</Box>
				<Button disabled={!agreeChecked} onClick={() => setStep(2)} variant="contained" className="continue-button-form">
					¡Quiero que me contacten!
				</Button>
			</>
		)
	}

	const renderStep3 = () => {
		return (
			<>
				<Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: colors.primary_green, borderRadius: "40px", height: "80px", width: "80px", marginTop: "50px", marginBottom: "16px" }}>
					<CheckRounded sx={{ fontSize: 60, color: "white" }} />
				</Box>
				<Typography variant='title6' color="primary">
					¡Hemos recibido tus datos!
				</Typography>
				<Typography variant='body3' sx={{ marginBottom: "100px" }}>
					En breve nos estaremos poniendo en contacto contigo.
				</Typography>
				<Typography variant='caption1' sx={{ marginBottom: "16px" }}>
					Estamos a tu lado para ayudarte en lo que necesites.
				</Typography>
				<Button onClick={() => closeForm()} variant="contained" className="continue-button-form">
					Ir a la Página Principal
				</Button>
			</>
		)
	}

	return (
		<Dialog
			keepMounted={false}
			fullScreen={isMobile}
			open={props.formOpen}
			onClose={props.handleFormClose}
			PaperProps={{
				sx: {
					maxWidth: "1062px",
					width: "100%",
					minHeight: "500px",
					borderRadius: isMobile ? "0px" : "16px",
					borderColor: "transparent",
					margin: 0
				}
			}}
			style={{marginTop: "8rem"}}
		>
			{product != null ? <Grid container minHeight="565px">
				<Grid item xs={12} sm={5}>
					<Box sx={{ backgroundColor: "black", height: "100%", margin: 0, padding: 0, display: "flex", alignItems: "center", justifyContent: "center", borderRadius: isMobile ? "0px" : "16px", overflow: "hidden", position: "relative", minHeight: "350px" }}>
						<img 
							alt='' 
							src={product.formImg} 
							objectFit="cover" 
							layout="fill" 
							style={{width: "100%"}}
						/>
						<Box sx={{ position: "absolute", bottom: 0, left: "28px", paddingRight: "55px", marginBottom: "32px" }}>
							<Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
								<Box sx={{ backgroundColor: colors.primary_green, width: "8px", height: "58px", marginRight: "8px" }} />
								<Typography variant='title4' color="white">
									Incrementa tus ingresos,
									vende tu cultivo de<span style={{ color: colors.primary_green }}>{" " + product.name}</span>
								</Typography>
							</Box>
							<Typography variant='body3' color="white">
								Nosotros captamos a los posibles interesados.<br />
								Hemos ayudado a <span style={{ color: colors.primary_green, fontWeight: "700" }}>2,527 personas a crecer su negocio.</span>
							</Typography>
						</Box>
					</Box>
				</Grid>
				<Grid item xs={12} sm={7} sx={{ paddingLeft: isMobile ? "16px" : "80px", paddingRight: isMobile ? "16px" : "80px", display: "flex", flexDirection: 'column', alignItems: "center" }}>
					{step < 2 ? <Box sx={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "space-between", alignItems: "center", marginTop: "26px", marginBottom: "26px" }}>
						<Button onClick={() => goBack()}>
							<ChevronLeftRounded />
						</Button>
						<Typography variant='title8' color="primary" textAlign="center">
							Déjanos tus datos y te llamamos
						</Typography>
						<Button sx={{ visibility: "hidden" }}>
							<ChevronLeftRounded />
						</Button>
					</Box> : ""}
					{step < 2 ? <Stepper alternativeLabel sx={{ minWidth: "204px", marginBottom: "16px" }} activeStep={step}>
						<Step>
							<StepLabel>

							</StepLabel>
						</Step>
						<Step>
							<StepLabel>

							</StepLabel>
						</Step>
					</Stepper> : ""}
					{step == 0 ? renderStep1() : ""}
					{step == 1 ? renderStep2() : ""}
					{step == 2 ? renderStep3() : ""}
				</Grid>
			</Grid> : ""}
		</Dialog>
	)
}

export default ProductForm;
