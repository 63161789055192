import colors from 'theme/colors';

let sx = {
	mainContainer: {
		backgroundColor: colors.light_background,
		paddingTop: "80px",
		paddingBottom: "8px",
		display: "flex",
		flexDirection: "column",
		alignItems: "center"
	},
	mainContainerMobile: {
		backgroundColor: colors.light_background,
		paddingTop: "16px",
		paddingBottom: "8px",
		display: "flex",
		flexDirection: "column",
		alignItems: "center"
	},
	mainGrid: {
		// marginTop: "85px",
		// marginBottom: "48px",
	},
	gridItem: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
	},
	infoCard: {
		maxWidth: "846px",
		width: "100%",
		padding: "0px",
		borderRadius: "24px",
		overflow: "hidden",
		display: "flex",
		flexDirection: "row",
		flexWrap: "wrap",
		borderWidth: 0,
	},
	infoImage: {
		height: "215px",
		borderRadius: "12px",
		overflow: "hidden",
		marginBottom: "16px"
	},
	arrowInfo: {
		fontSize: "40px",
		marginTop: "48px",
		color: colors.primary_green
	}

}

export default sx;