import { Container } from "@mui/material";
import { useRouteError } from "react-router-dom";

export default function ErrorPage() {
  const error = useRouteError();
  console.error(error);

  return (
    <Container maxWidth="sm" id="error-page">
        <h1>Oops!</h1>
        <p>Hubo un error al cargar la ruta solicitada</p>
        <p>
            <i>{error.statusText || error.message}</i>
        </p>
    </Container>
  );
}