import { initializeApp, getApp} from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getRemoteConfig } from "firebase/remote-config";
import { getValue } from "firebase/remote-config";
import { getFirestore } from 'firebase/firestore'
import { getFunctions, connectFunctionsEmulator} from 'firebase/functions';

const firebaseConfig = {
	apiKey: "AIzaSyBLLIPEO7N6-Y_RQUTKbyqregCajLFPj8s",
	authDomain: "treembo-70bf0.firebaseapp.com",
	databaseURL: "https://treembo-70bf0.firebaseio.com",
	projectId: "treembo-70bf0",
	storageBucket: "treembo-70bf0.appspot.com",
	messagingSenderId: "173973261783",
	appId: "1:173973261783:web:ec4df006f89848c2c5cf92",
	measurementId: "G-TXP6NQS9B3"
};

let app; 
let analytics; 
let db;
let remoteConfig;
let abtest_val;
let functions;

if (firebaseConfig?.projectId) {
	// Initialize Firebase
	app = initializeApp(firebaseConfig);

	if (app.name && typeof window !== 'undefined') {
		analytics = getAnalytics(app);

		remoteConfig = getRemoteConfig();
		remoteConfig.settings.minimumFetchIntervalMillis = 3600000;
		remoteConfig.defaultConfig = {
			"landing_variant": "A"
		};
		abtest_val = getValue(remoteConfig, "landing_variant");
		
		db = getFirestore(app);
		functions = getFunctions(app);
		// connectFunctionsEmulator(functions, "localhost", 5001);
	}
}

export { app, analytics, db, remoteConfig, abtest_val, functions};