import { createTheme } from '@mui/material/styles'
import colors from './colors';

const TreemboTheme = createTheme({
	palette: {
		primary: {
			main: '#1AA66B',
		},
		secondary: {
			main: "#FFFFFF",
		},
		error: {
			main: '#D34D36'
		},
		white: "#FFFFFF",
		hover_green: "#179460",
		dark_gray: "#333333",
		medium_gray: "#6D6D6D",
		medium_light_gray: "#CFCFCF",
		light_gray: "#F1F1F1",
		sunset: "#F55432",
		twilight: "#294B59",
		soil: "#966632",
		sun: "#F1B755",
		field: "#5D913C",
		water: "#1DADBE",
		blue_detail: {
			main: "#0E4EA5"
		},
	},
	typography: {
		fontFamily: "Roboto",
		// TITLE
		title1: {
			fontSize: "3rem",
			fontWeight: "900"
		},
		title2: {
			fontSize: "2.5rem",
			fontWeight: "900"
		},
		title3: {
			fontSize: "2rem",
			fontWeight: "900"
		},
		title4: {
			fontSize: "1.75rem",
			fontWeight: "900"
		},
		title5: {
			fontSize: "1.5rem",
			fontWeight: "900"
		},
		title6: {
			fontSize: "1.25rem",
			fontWeight: "400"
		},
		title6Bold: {
			fontSize: "1.25rem",
			fontWeight: "900"
		},
		title7: {
			fontSize: "1.125rem",
			fontWeight: "900"
		},
		title8: {
			fontSize: "1rem",
			fontWeight: "900"
		},
		title9: {
			fontSize: "0.875rem",
			fontWeight: "900"
		},
		// BODY
		body1: {
			fontSize: "1.25rem",
		},
		body2: {
			fontSize: "1rem",
		},
		body3: {
			fontSize: "0.875rem",
		},
		body4: {
			fontSize: "0.85rem",
		},
		// CAPTION
		caption1: {
			fontSize: "0.75rem",
		},
		caption2: {
			fontSize: "0.563rem",
		},
		// BADGE
		badge1: {
			fontSize: "0.75rem",
			fontWeight: "bold"
		},
		badge2: {
			fontSize: "0.563rem",
			fontWeight: "bold"
		},
		// OVERLINE
		overline: {
			fontSize: "0.75rem",
			fontWeight: "bold"
		}
	},
	components: {
		MuiButton: {
			styleOverrides: {
				contained: {
					textTransform: "none",
					borderRadius: "22px",
					margin: "0px",
					boxShadow: "0px 0px 12px rgba(51, 51, 51, 0.12)",
					border: "none",
					paddingRight: "24px",
					paddingLeft: "24px",
					fontSize: "1rem",
					fontWeight: "700"
				},
				outlined: {
					textTransform: "none",
					borderRadius: "22px",
					margin: "0px",
					paddingRight: "24px",
					paddingLeft: "24px",
					fontSize: "1rem",
					fontWeight: "700"
				},
				text: {
					textTransform: "none",
					borderRadius: "22px",
					margin: "0px",
					paddingRight: "12px",
					paddingLeft: "12px",
					fontSize: "1rem",
					fontWeight: "700"
				},
				image: {
					margin: "0px",
					paddingRight: "0px",
					paddingLeft: "0px",
					color: colors.primary_green
				},
			}
		},
		MuiInputBase: {
			styleOverrides: {
				root: {
					fontSize: "14px",
				},
			},
		},
		MuiOutlinedInput: {
			styleOverrides: {
				root: {
					borderRadius: "32px"
				}
			}
		},
		MuiInputLabel: {
			styleOverrides: {
				root: {
					fontSize: "14px",
				}
			}
		},
		MuiInputAdornment: {
			styleOverrides: {
				root: {
					fontSize: "14px"
				}
			}
		},
		// MuiOutlinedInput: {
		// 	styleOverrides: {
		// 		root: {
		// 			borderRadius: "32px",
		// 			fontSize: "16px"
		// 		}
		// 	},
		// 	defaultProps: {
		// 		size: "small"
		// 	}
		// },
		MuiMenuItem: {
			styleOverrides: {
				root: {
					fontSize: "14px",
					// '&.Mui-selected': {
					// 	backgroundColor: "rgba(14, 78, 165, 0.08)",
					// 	borderLeft: "4px solid #0E4EA5"
					// },
					// '&.Mui-selected:hover': {
					// 	backgroundColor: "rgba(14, 78, 165, 0.08)",
					// },
					// '&.Mui-selected.Mui-focusVisible': {
					// 	backgroundColor: "rgba(14, 78, 165, 0.08)",
					// }

				}
			}
		},
		MuiSvgIcon: {
			styleOverrides: {
				root: {
					'&.MuiSelect-icon&.Mui-disabled': {
						color: "#CFCFCF"
					},
					zIndex: 1
				}
			}
		},
		MuiAutocomplete: {
			styleOverrides: {
				root: {
					width: '100%'
				},
				popupIndicatorOpen: {
					transform: 'none'
				}
			}
		},
		MuiPaper: {
			styleOverrides: {
				root: {
					boxShadow: "0px 8px 32px rgba(51, 51, 51, 0.12)"
				}
			}
		},
		MuiRadio: {
			styleOverrides: {
				root: {
					'@media (min-width:900px)': {
						paddingLeft: '9px'
					},
					'@media (max-width:899px)': {
						paddingLeft: '0px'
					},
				}
			}
		},
		MuiAccordion: {
			styleOverrides: {
				root: {
					boxShadow: "none",
					'&.Mui-expanded': {
						boxShadow: "0px 8px 32px rgba(51, 51, 51, 0.12)"
					}
				}
			}
		},
		MuiAccordionSummary: {
			styleOverrides: {
				root: {
					'& div.MuiExpansionPanelSummary-expandIcon': {
						position: 'absolute',
						right: '0px',
					},
				}
			}
		},
		MuiFab: {
			styleOverrides: {
				root: {
					position: 'fixed',
					bottom: "16px",
					right: "16px",
				}
			}
		},
		MuiStepIcon: {
			styleOverrides: {
				root: {
					height: "40px",
					width: "40px",
				},
				text: {
					fontWeight: "bold"
				}
			}
		},
		MuiStepConnector: {
			styleOverrides: {
				root: {
					backgroundColor: colors.primary_green,
					marginTop: "6px"
				}
			}
		},
		MuiListItemButton: {
			styleOverrides: {
				root: {
					'&:hover': {
						backgroundColor: colors.active_green
					}
				},
			}
		},
		MuiListItemText: {
			styleOverrides: {
				primary: {
					fontSize: "14px"
				},
				root: {
					
				}
			}
		}
	},

	breakpoints: {
		values: {
			xs: 0,
			sm: 600,
			md: 900,
			lg: 1200,
			xl: 1536,
			laptop: 1440,
		},
	},
})

export default TreemboTheme;