/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { Container, Grid, Typography, Divider, Button, Box, CircularProgress, Snackbar, Alert } from '@mui/material';
import { PlaceOutlined, Verified, ChevronLeft, ChevronRight, WhatsApp, ShoppingCart, AddRounded, RemoveRounded, ShareRounded} from '@mui/icons-material';
import { collection, doc, getDoc, getDocs } from "firebase/firestore";
import { httpsCallable } from 'firebase/functions';
import { functions } from "../../../services/firebase/firebaseConfig";
import { db } from 'services/firebase/firebaseConfig';
import { getAnalytics, logEvent } from 'firebase/analytics'
import imgSuffix from 'common/image-suffix';
import moment from "moment";
import constants from 'common/constants';
import appusers from 'services/loopback/appusers';
import colors from "theme/colors";
import { useParams, useNavigate } from 'react-router-dom';
import shipping_ids from 'common/shipping_ids';
import TiktokPixel from 'utils/tiktok_pixel';
import percentageDifference from 'utils/percentageDifference';

import Discount from "assets/svgs/product_insignia/discount.svg"
import FreeShipping from "assets/svgs/product_insignia/free_shipping.svg"
import MSI from "assets/svgs/product_insignia/msi.svg"
import Offer from "assets/svgs/product_insignia/offer.svg"
import SameDayShipping from "assets/svgs/product_insignia/same_day_shipping.svg"

const ProductScreen = (props) => {
	const navigate = useNavigate()
    const params = useParams();
	const [loading, setLoading] = useState(true);
	const [productId, setProductId] = useState(params.productId);
	const [product, setProduct] = useState(null);
	const [stripe_product, set_stripe_product] = useState(null);
	const [store, setStore] = useState(null);
	const [owner, setOwner] = useState(null);
	const [imgIndex, setImgIndex] = useState(0);
	const [quantity, set_quantity] = useState(1);
	const [loading_session, set_loading_session] = useState(false);
	const [share_snack, set_share_snack] = useState(false);

	useEffect(() => {
		const analytics = getAnalytics();
		logEvent(analytics, 'screen_view', {
			firebase_screen: "Producto_Web", 
			firebase_screen_class: "Producto_Web"
		});
		TiktokPixel.pageView();
		init()
	},[])

	const init = () => {
		if(productId !== ""){
			getProduct()
			updateClicks()
		}else{
			setLoading(false)
		}
	} 

	const updateClicks = async () => {
        // try {
			const instance = httpsCallable(functions, 'products-productClick')
			instance({
				uid: productId
			})
            .then((result) => {
                console.log("response: ", result)
            })
            .catch((error) => {
                console.log("error: ", error)
            });
		// } catch (error) {
		// 	//console.log("error: ", error)
		// 	return false
		// }
    }

	const getProductOwner = async (uid) => {
		let response = await appusers.getAppUserByUID(uid)
		if(response){
			setOwner(response)
		}
		setLoading(false)
	}

	const getProduct = async () => {
		try {
			const docRefProduct = doc(db, constants.mode + "products", productId);
			const docSnapProduct = await getDoc(docRefProduct);
			if(docSnapProduct.data()){
				var auxFiles = []
				if(docSnapProduct.data().files){
					let technicalFiles = []
					let securityFiles = []
					let otherFiles = []
					docSnapProduct.data().files.forEach((file) => {
						if(file.type == "technical_datasheet"){
							technicalFiles.push(file)
						}else if(file.type == "security_datasheet"){
							securityFiles.push(file)
						}else{
							otherFiles.push(file)
						}
					})
					auxFiles = [...technicalFiles, ...securityFiles, ...otherFiles]
				}
				let data = {
					...docSnapProduct.data(),
					price: parseFloat(docSnapProduct.data().price),
					uid: docSnapProduct.id,
					files: auxFiles
				}
				if(data.disabled){
					setLoading(false)
					return setProduct(null)
				}
				setProduct(data);
				TiktokPixel.track('ViewContent',{
					content_id: data.uid,
					content_name: data.name,
					content_type: "product",
					value: data.price,
					currency: 'mxn'
				})
				let analyticsItems = [{
					item_id: data.uid,
					item_name: data.name,
					item_brand: data.store ? data.store.label : ""
				}]
				const analytics = getAnalytics();
				logEvent(analytics, 'view_item', {
					value: data.price,
					currency: data.currency ? data.currency : "",
					items: analyticsItems
				});
				if(data.stripe_payment){
					try {
						const stripedocRefProduct = doc(db, constants.mode + "stripe_products", productId);
						const stripedocSnapProduct = await getDoc(stripedocRefProduct);
						if(stripedocSnapProduct.exists()){
							let activePrice = null;
							const pricesRef = await collection(db, constants.mode + `/stripe_products/${stripedocSnapProduct.id}/prices`)
							const pricesSnap = await getDocs(pricesRef);
							pricesSnap.docs.forEach((doc) => {
								console.log(doc.id, ' => ', doc.data());
								if(doc.data().active){
									activePrice = {
										id: doc.id,
										...doc.data()
									}
								}
							});
							let prod = {
								activePrice,
								...stripedocSnapProduct.data()
							}
							console.log('STRIPE_PRODUCT: ', prod);
							set_stripe_product(prod)
						}
					} catch (error) {
						console.log('ERROR_GETTING_STRIPE_PRODUCT: ', error)
					}
				}
				if(data.store){
					try {
						const docRefStore = doc(db, constants.mode + "stores", data.store.id);
						const docSnapStore = await getDoc(docRefStore);
						setStore(docSnapStore.data());
						setLoading(false)
					} catch (error) {
						console.log('ERROR_GETTING_STORE_DATA: ',error)
					}
				}else{
					getProductOwner(data.ownerUID)
				}
			}else{
				setLoading(false)
			}
		} catch (error) {
			setLoading(false)
			console.log("Get product error: ", error)
		}
	}

	const openContact = async (type) => {
		try {
			if(type === "whatsapp"){
				window.open(
					"https://wa.me/523329504314?text=" + 
					`Hola, me interesa el producto ${product.name} de ${store ? "la tienda oficial " + store.name : owner.personalInfo.fullName}: ${constants.URL.home}product/${product.uid}`
				)
			}else if(type === "phone"){
				window.open(
					"tel:+523329504314"
				)
			}
		} catch (error) {
			console.log("CONTACT_ERROR: ", error)
		}
	}

	useEffect(()=>{
		if(localStorage.getItem("payment_product")){
			localStorage.removeItem("payment_product")
		}
	},[])

	const openPurchase = async () => {
		try {
			set_loading_session(true)
			const createStripeSession = httpsCallable(functions, 'products-createStripeSession')
			let shipping = null;
			if(product.free_shipping){
				shipping = shipping_ids.free
			} else if(quantity <= 6) {
				shipping = shipping_ids.standard_1_to_6
			} else {
				shipping = shipping_ids.standard_7_to_12
			}
			TiktokPixel.track('InitiateCheckout',{
				content_id: product.uid,
				content_name: product.name,
				content_type: "product",
				value: product.price,
				currency: 'mxn',
				quantity: quantity
			})
			let analyticsItems = [{
				item_id: product.uid,
				item_name: product.name,
				item_brand: product.store ? product.store.label : "",
				quantity: quantity
			}]
			const analytics = getAnalytics();
			logEvent(analytics, 'begin_checkout', {
				value: product.price,
				currency: product.currency ? product.currency : "",
				items: analyticsItems
			});
			const response = await createStripeSession({
				line_items: [{
					price: stripe_product.activePrice.id,
					quantity: quantity,
				}],
				cancel_url: window.location.href,
				shipping_options: [
					shipping
				]
			})
			console.log('RESPONSE_CREATE_STRIPE_SESSION: ', response);
			if(response.data && response.data.id && response.data.url){
				window.open(response.data.url,'_self')
				localStorage.setItem("payment_product", JSON.stringify({
					product: product,
					quantity: quantity,
				}))
			} else {
				set_loading_session(false)
			}
		} catch (error) {
			set_loading_session(false)
			console.log("ERROR_CREATE_STRIPE_SESSION: ", error)
		}
	}

	const nextImg = () => {
		let current = imgIndex
		if(product.images){
			if(current === product.images.length - 1){
				current = 0
			}else{
				current++
			}
		}else if(product.legacy_images){
			if(current === product.legacy_images.length - 1){
				current = 0
			}else{
				current++
			}
		}
		setImgIndex(current)
	}

	const prevImg = () => {
		let current = imgIndex
		if(product.images){
			if(current === 0){
				current = product.images.length - 1
			}else{
				current--
			}
		}else if(product.legacy_images){
			if(current === 0){
				current = product.legacy_images.length - 1
			}else{
				current--
			}
		}
		setImgIndex(current)
	}

	const addQuantity = () => {
		if(quantity < 12){
			set_quantity(quantity + 1)
		}
	}

	const subtractQuantity = () => {
		if(quantity > 1){
			set_quantity(quantity - 1)
		}
	}

	const handleShareURL = async () => {
		await navigator.clipboard.writeText(window.location.href);
		set_share_snack(true)
	}

	const _renderStore = () => {
		try {
			return (
				<div>
					<Grid sx={{ display: "flex", flexDirection: "row", alignItems: "center", marginTop: "16px" }}>
						<div style={{borderRadius: "15px", overflow: "hidden", width: "30px", height: "30px", backgroundColor: "#" + store.backgroundColor}}>
							<img
								alt="img" 
								src={store.logo + imgSuffix.lg} 
								height={30} 
								width={30} 
							/>
						</div>
						<Typography variant="body2" sx={{ marginLeft: "8px", marginTop: "2px", fontWeight: "bold" }}>
							{store.name}
						</Typography>
						<Verified sx={{ fontSize: "16px", marginLeft: "4px", color: "#1fadc1" }}/>
					</Grid>
					{/* <Grid sx={{ display: "flex", flexDirection: "row", marginTop: "8px"  }}>
						<PlaceOutlined fontSize="small" sx={{ color: colors.primary_green }}/>
						<Typography variant="body2" sx={{ paddingLeft: "4px" }}>
							{store.location}
						</Typography>
					</Grid> */}
					{product.whatsapp_enabled && product.whatsapp_enabled == true ? 
						<Grid sx={{ display: "flex", flexDirection: "row", justifyContent: "center", marginTop: "8px" }}>
							<Button variant={stripe_product ? 'outlined' : 'contained'} sx={{ display: "flex", flexDirection: "row", width: "100%" }} onClick={()=>openContact("whatsapp")}>
								<WhatsApp fontSize="small"/>
								<Typography variant="body3" sx={{ marginLeft: "8px" }}>
									WhatsApp
								</Typography>
							</Button>
						</Grid>
					:<></>}
					<Divider sx={{ marginTop: "16px"}}/>
				</div>
			)
		} catch (error) {
			console.log("LOAD_SELLER_ERRRO: ", error)
			return <Typography 
				variant="body2" 
				sx={{ marginTop: "16px"}}
			>
				Error al cargar la tienda oficial
			</Typography>
		}
	}

	const _renderOwner = () => {
		try {
			return (
				<div>
					<Typography variant="body2" sx={{ marginTop: "16px"}}>
						Acerca del vendedor
					</Typography>
					<Grid sx={{ display: "flex", flexDirection: "row", alignItems: "center", marginTop: "16px" }}>
						<div style={{borderRadius: "15px", overflow: "hidden", width: "30px", height: "30px"}}>
							<img
								alt="img"
								src={constants.uploadedImages + owner.profilePictureURL + "180x180"} height={30} 
								width={30} 
							/>
						</div>
						<Typography variant="body2" sx={{ marginLeft: "8px", fontWeight: "bold"}}>
							{owner.personalInfo.fullName}
						</Typography>
					</Grid>
					<Grid sx={{ display: "flex", flexDirection: "row", marginTop: "8px"  }}>
						<PlaceOutlined fontSize="small" sx={{ color: colors.primary_green}}/>
						<Typography variant="body2" sx={{ paddingLeft: "4px"}}>
							{owner.personalInfo.address.name}
						</Typography>
					</Grid>
					{product.whatsapp_enabled && product.whatsapp_enabled == true ? 
						<Grid sx={{ display: "flex", flexDirection: "row", justifyContent: "center", marginTop: "8px" }}>
							<Button variant="contained" sx={{ display: "flex", flexDirection: "row", backgroundColor: colors.primary_green, width: "100%" }} onClick={()=>openContact("whatsapp")}>
								<WhatsApp fontSize="small" sx={{ color: "white"}}/>
								<Typography variant="body3" sx={{ color: "white", marginLeft: "8px" }}>
									WhatsApp
								</Typography>
							</Button>
						</Grid>
					:<></>}
					<Divider sx={{ marginTop: "16px"}}/>
				</div>
			)
		} catch (error) {
			console.log("LOAD_SELLER_ERRRO: ", error)
			return <Typography 
				variant="body2" 
				sx={{ marginTop: "16px"}}
			>
				Error al cargar el vendedor
			</Typography>
		}
	}

	const returnSingularUnit = (type) => {
		switch(type){
			case "square_meters":
				return "Metro cuadrado"
			case "hectares":
				return "Hectárea"
			case "acres":
				return "Acre"
			case "centimeters": 
				return "Centímetro"
			case "inches":
				return "Pulgada"
			case "meters":
				return "Metro"
			case "kilometers": 
				return "Kilometro"
			case "grams":
				return "Gramo"
			case "ounces":
				return "Onza"
			case "pounds":
				return "Libra"
			case "kilograms":
				return "Kilogramo"
			case "tons":
				return "Tonelada"
			case "liters": 
				return "Litro"
			case "gallons":
				return "Galón"
			case "burlaps":
				return "Arpilla"
			case "cartons": 
				return "Cartón"
			case "buckets":
				return "Cubeta"
			case "cubic_meters":
				return "Metro cúbico"
			case "cubic_centimeters":
				return "Centimetro cúbico"
			case "piece":
				return "Pieza"
			case "boxes":
				return "Caja"
			case "sack":
				return "Costal"
			default:
				return ""
		}
	}

	const returnPluralUnit = (value, type) => {
		switch(type){
			case "square_meters":
				return "" + value + " Metros cuadrados"
			case "hectares":
				return "" + value + " Hectáreas"
			case "acres":
				return "" + value + " Acres"
			case "centimeters": 
				return "" + value + " Centímetros"
			case "inches":
				return "" + value + " Pulgadas"
			case "meters":
				return "" + value + " Metros"
			case "kilometers": 
				return "" + value + " Kilometros"
			case "grams":
				return "" + value + " Gramos"
			case "ounces":
				return "" + value + " Onzas"
			case "pounds":
				return "" + value + " Libras"
			case "kilograms":
				return "" + value + " Kilogramos"
			case "tons":
				return "" + value + " Toneladas"
			case "liters": 
				return "" + value + " Litros"
			case "gallons":
				return "" + value + " Galones"
			case "burlaps":
				return "" + value + " Arpillas"
			case "cartons": 
				return "" + value + " Cartones"
			case "buckets":
				return "" + value + " Cubetas"
			case "cubic_meters":
				return "" + value + " Metros cúbicos"
			case "cubic_centimeters":
				return "" + value + " Centimetros cúbicos"
			case "piece":
				return "" + value + " Piezas"
			case "boxes":
				return "" + value + " Cajas"
			case "sack":
				return "" + value + " Costales"
			default:
				return ""
		}
	}

	const _renderUnitText = (value, type) => {
		try {
			if(value <= 1 || value == "1"){
                if(typeof type === "string"){
                    return returnSingularUnit(type)
                }else{
                    return returnSingularUnit(type.id)
                }
			}else{
                if(typeof type === "string"){
					return returnPluralUnit(value, type)
                }else{
                    return returnPluralUnit(value, type.id)
                }
			}
		} catch (error) {
			return ""
		}
    }

	const _renderStripeBlock = () => {
		if(loading_session){
			return <div style={{height: '4.4rem', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', width: '100%'}}>
				<CircularProgress sx={{width: '100%',}} variant="indeterminate"/>
			</div>
		}
		return <>
			<div style={{display: "flex", flexDirection: "row",alignItems: "center"}}>
				{stripe_product && !product.free_shipping ? <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
					<Typography variant='body3' style={{fontWeight: "bold", color: colors.primary_green}}>{`Envío Estándar ${quantity > 6 ? '$300.00 mxn' : '$180.00 mxn'}`}</Typography>
				</div> : <></>}
			</div>
			{stripe_product ? <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
				<Typography variant='body2' sx={{marginRight: '1rem'}}>
					Cantidad:
				</Typography>
				<div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
					<div onClick={subtractQuantity} style={{height: '2.2rem', width: '2.2rem', borderRadius: '1.1rem', display: 'flex', alignItems: 'center', justifyContent: 'center', color: colors.primary_green, fontSize: '1.8rem', cursor: 'pointer'}}>
						<RemoveRounded fontSize='24'/>
					</div>
					<div style={{height: '2.2rem', width: '2rem', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
						{quantity}
					</div>
					<div onClick={addQuantity} style={{height: '2.2rem', width: '2.2rem', borderRadius: '1.1rem', display: 'flex', alignItems: 'center', justifyContent: 'center', color: colors.primary_green, fontSize: '1.8rem', cursor: 'pointer'}}>
						<AddRounded fontSize="24"/>
					</div>
				</div>
			</div> : <></>}
			{stripe_product && quantity <= 12 ? <Grid sx={{ display: "flex", flexDirection: "row", justifyContent: "center", marginTop: "8px" }}>
				<Button variant="contained" sx={{ display: "flex", flexDirection: "row", backgroundColor: "black", boxShadow: 3, width: "100%" }} onClick={()=>openPurchase()}>
					<ShoppingCart fontSize="small" sx={{ color: "white"}}/>
					<Typography variant="body3" sx={{ color: "white", marginLeft: "8px" }}>
						COMPRAR AHORA
					</Typography>
				</Button>
			</Grid> : <></>}
			<Box style={{display: "flex", flexDirection: "row", flexWrap: "wrap"}}>
				{product.free_shipping ? <Box style={{display: "flex", flexDirection: "row", alignItems: "center", marginTop: "8px", marginRight: "8px"}}>
					<img 
						src={FreeShipping} 
						height={18} 
						width={18} 
						alt=''
						style={{marginRight: "8px"}}
					/>
                    <Typography variant='body3' style={{fontFamily: "Roboto", color: colors.primary_green}}>
                        Envío gratis
                    </Typography>
                </Box> : <></>}
                {product.msi ? <Box style={{display: "flex", flexDirection: "row", alignItems: "center", marginTop: "8px", marginRight: "8px"}}>
					<img 
						src={MSI} 
						height={18} 
						width={18} 
						alt=''
						style={{marginRight: "8px"}}
					/>
                    <Typography variant='body3' style={{fontFamily: "Roboto", color: colors.primary_green}}>
                        Meses sin intereses
                    </Typography>
                </Box> : <></>}
                {product.promotion ? <Box style={{display: "flex", flexDirection: "row", alignItems: "center", marginRight: "8px", marginTop: "8px"}}>
					<img 
						src={Offer} 
						height={18} 
						width={18} 
						alt=''
						style={{marginRight: "8px"}}
					/>
                    <Typography variant='body3' style={{fontFamily: "Roboto", color: colors.primary_green}}>
                        Promoción
                    </Typography>
                </Box> : <></>}
            </Box>
			{stripe_product ? <div style={{height: '0.5rem'}}/> : <></>}
		</>
	}

	try {
		if(loading){
			return <Container>
						<Box minHeight={250} style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
							<Typography>
								Cargando...
							</Typography>
						</Box>
					</Container>
		}
		return (
			product != null ? 
				<Container maxWidth="md" sx={{ display: "flex", flexDirection: "column", paddingBottom: "40px" }}>
					<Box sx={{marginTop: "16px", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center"}}>
						<ChevronLeft sx={{ fontSize: 36, marginRight: "16px" }} color="primary" onClick={()=>{navigate(-1)}}/>
						<Button onClick={handleShareURL} startIcon={<ShareRounded/>} variant='outlined'>
							Compartir
						</Button>
					</Box>
					<Divider sx={{ marginTop: "16px"}}/>
					<Grid container maxWidth="4" sx={{ display: "flex", flexDirection: "row", justifyContent: "center", alignSelf: "center", marginTop: "16px", backgroundColor: "white" }}>
						<Grid item xs={12} sm={7} md={8} sx={{ display: "flex", justifyContent: "center", backgroundColor: "white", position: "relative", alignItems: "center", marginBottom: "8px"}}>
                            <Box sx={{width: "100%", maxHeight: "30rem", height: "100%",display: "flex", flexDirection: "row", justifyContent: "center"}}>
                                {product.images ?
									<img
										alt="img"
										src={product.images[imgIndex] + imgSuffix.lg} 
										style={{height: "auto", width: "auto", objectFit: "contain", borderRadius: "5%", maxHeight: "100%", maxWidth: "100%"}}
									/>
                                : (product.legacy_images ? 
									<img
										alt="img"
										src={product.legacy_images[imgIndex] + "480x480"}
										style={{height: "100%", width: "100%", objectFit: "contain", borderRadius: "5%"}}
									/> 
                                : "")}
                            </Box>
							{((product.images && product.images.length > 1) || (product.legacy_images && product.legacy_images.length > 1)) ? <>
								<Button 
									style={{position: "absolute", left: 0, backgroundColor: "#FFFFFF8F", height: "50px", width: "50px", borderTopLeftRadius: 0, borderTopRightRadius: "25px", borderBottomLeftRadius: 0, borderBottomRightRadius: "25px", minWidth: "50px"}}
									onClick={()=>prevImg()}
								>
									<ChevronLeft/>
								</Button>
								<Button
									style={{position: "absolute", right: 0, backgroundColor: "#FFFFFF8F", height: "50px", width: "50px", borderTopLeftRadius: "25px", borderTopRightRadius: "0px", borderBottomLeftRadius: "25px", borderBottomRightRadius: "0px", minWidth: "50px"}}
									onClick={()=>nextImg()}
								>
									<ChevronRight/>
								</Button>
							</> : ""}
						</Grid>
						<Grid item xs={12} sm={5} md={4} sx={{paddingLeft: "8px", paddingRight: "8px", backgroundColor: "white", display: "flex", flexDirection: "column"}}>
							<Typography variant="title5" sx={{ fontWeight: "bold", color: colors.twilight}}>{product.name}</Typography>
							{product.hasDiscount && product.hasDiscount == true ?
								<Box item sx={{ display: "flex", flexDirection: "row", alignItems: "flex-end" }}>
									<Typography variant="caption1" sx={{ color: colors.medium_gray, textDecorationLine: "line-through" }}>${product.previousPrice ? product.previousPrice.toLocaleString("en-US") : 0}&nbsp;</Typography>
								</Box> 
							:null}
							{product.price && product.price !== 0 && product.price !== "" && product.price !== null ?
								<Box sx={{ display: "flex", flexDirection: "row", marginTop: "0px", alignItems: "end" }}>
									<Typography variant="body1" sx={{ fontWeight: 'bold'}}>${product.price.toLocaleString("en-US")}</Typography>
									{product.discountPercentageVisible && product.hasDiscount && product.hasDiscount == true && product.previousPrice && parseFloat(product.previousPrice) > 0 ? 
										<Box style={{display: "flex", flexDirection: "row", alignItems: "center", marginLeft: "8px"}}>
											<Typography style={{color: colors.primary_green}}>
												%{percentageDifference(product.previousPrice, product.price)} OFF
											</Typography>
											<Box sx={{marginLeft: "8px"}}>
												<img 
													src={Discount} 
													height={18} 
													width={18} 
													alt=''
												/>
											</Box>
										</Box>
                                	:<></>}
								</Box>
							:<></>}
							{product.price && product.price && product.unitQuantity > 0 ? <>
								<Typography sx={{fontSize: 14, color: "black"}}>
									{_renderUnitText(product.unitQuantity,product.unit)}{", "}
									<span style={{fontWeight: "bold", color: colors.primary_green}}>
										{`$${new Intl.NumberFormat().format(parseFloat(product.price / product.unitQuantity).toFixed(2))} por ${_renderUnitText(1,product.unit)}`}
									</span>
								</Typography>
							</> : <></>}

							{_renderStripeBlock()}

							{/* PROMOTION BLOCK */}
							{/* <Box sx={{ display: "flex", flexDirection: "row", marginBottom: "16px" }}>
								{product.promotion != "" && product.promotion != null ?
									<Box sx={{marginTop: "16px", display: "flex", backgroundColor: "sunset", height: "24px", borderRadius: "12px", paddingLeft: "8px", paddingRight: "8px", alignItems: "center", paddingBottom: "2px"}}>
										<Typography variant="body4" sx={{display: '-webkit-box', overflow: 'hidden', WebkitBoxOrient: 'vertical', WebkitLineClamp: 1, color: "white", marginTop: "2px" }}>{product.promotion}</Typography>
									</Box>
								:null}
							</Box> */}
							{store != null && product.files.length > 0 ?
								<Grid container spacing={1} sx={{ marginTop: "8px", backgroundColor: "white" }}>
									{/* <Typography variant="title8">Documentación</Typography> */}
									{product.files.map((file, index) => {
										return	<Grid item xs={6} sx={{ backgroundColor: "white" }}>
													<Button key={file.id} href={file.url + "?alt=media"} target="_blank" rel="noopener noreferrer" sx={{ display: "flex", flexDirection: "row", height: "36px", padding: "12px", backgroundColor: colors.primary_green, alignItems: "center", justifyContent: "center", borderRadius: "18px"}}>
														<Typography variant="title9" sx={{ overflow: 'hidden', whiteSpace: "nowrap", textOverflow: "ellipsis", color: "white", cursor: "pointer" }}>{file.title}</Typography>
													</Button>
												</Grid>
									})}
								</Grid>
							:null}

							<Divider sx={{ marginTop: "16px"}}/>

							{(product.location || product.address.name) ? <>
								<Typography variant="body2" sx={{ marginTop: "16px", fontWeight: "bold"}}>
									Ubicación del producto
								</Typography>
								<Grid sx={{ display: "flex", flexDirection: "row", marginTop: "8px" }}>
									<PlaceOutlined fontSize="small" sx={{ color: colors.primary_green }}/>
									<Typography variant="body2" sx={{ paddingLeft: "4px"}}>{product.location || product.address.name}</Typography>
								</Grid>
							</> : <></>}

							<Divider sx={{ marginTop: "16px"}}/>
	
							{store != null ? _renderStore() :null}
	
							{owner != null ? _renderOwner() :null}
						</Grid>
					</Grid>
					<Grid container maxWidth="md" sx={{ display: "flex", flexDirection: "column", alignSelf: "center", paddingBottom: "16px", paddingLeft: "8px" }}>
						<Typography variant="body2" sx={{ marginTop: "16px", fontWeight: "bold"}}>Descripción del producto</Typography>
						<Typography variant="body2" style={{whiteSpace: "pre-wrap"}}>{product.description}</Typography>
					</Grid>
					<Snackbar
						anchorOrigin={{ vertical: "top", horizontal: "right" }}
						open={share_snack}
						autoHideDuration={3000}
						onClose={()=>set_share_snack(false)}
						action={()=>{}}
						sx={{zIndex: 100000}}
					>
						<Alert
							onClose={()=>set_share_snack(false)}
							severity="success"
							variant="filled"
							sx={{backgroundColor: colors.twilight}}
						>
							Enlace de producto copiado.
						</Alert>
					</Snackbar>
				</Container>
			: <Container>
				<Box minHeight={250} style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
					<Typography>
						Producto no disponible
					</Typography>
				</Box>
			</Container>
		)
	} catch (error) {
		console.log("ERROR_PRODUCT_LOAD: ", error)
		return <Container>
			<Box minHeight={250} style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
				<Typography>
					Hubo un problema al cargar el producto.
				</Typography>
			</Box>
		</Container>
	}
}

export default ProductScreen;
