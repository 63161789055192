import axios from "axios";
import constants from "common/constants";

const appusers = {
    getAppUserByUID: async (uid) => {
        try {
            let filter = {
                "where": {
                    "firebaseUID": uid
                }
            }
            let url = `${constants.URL.api}AppUsers?&filter=${JSON.stringify(filter)}`
            console.log("URL: ", url)
            const contactOptions = {
                method: 'GET',
                headers: {
                    'content-type': 'application/json',
                },
                url: url
            };
    
            // console.log("AXIOS GET ADMIN OPTIONS:", contactOptions)
            let createResponse = await axios(contactOptions);
            // console.log("AXIOS GET ADMIN RESPONSE:", createResponse)
    
            if (createResponse.status === 201 || createResponse.status === 200) {
                let userData = createResponse.data
                if(userData.length){
                    return userData[0]
                }
                return userData
            } else {
                console.log("GET_USER_BY_UID_ERROR: NOT FOUND")
                return false
            }
        } catch (error) {
            console.log("GET_USER_BY_UID_ERROR: ", error)
        }
    }
}

export default appusers