import * as React from 'react';
import TextField from '@mui/material/TextField';
import DatePicker from '@mui/lab/DatePicker';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import moment from "moment";

export default function MUIDatePicker(props) {
    const [value, setValue] = React.useState(new Date());
    const Today = moment()
    const Limit = moment().add(5,"years")

    React.useEffect(()=>{
        if(props.value){
            setValue(props.value)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    return (
        <DatePicker
            label={props.label}
            openTo="year"
            views={['year', 'month', 'day']}
            value={value}
            minDate={Today}
            maxDate={Limit}
            onChange={(newValue) => {
                setValue(newValue);
                props.setHarvestDate(newValue)
            }}
            components={{
                OpenPickerIcon: CalendarTodayOutlinedIcon
            }}
            renderInput={(params) => <TextField sx={{marginBottom: "16px"}} size='small' {...params} fullWidth={true} className={props.isBanners ? "banners-form-input" : "contact-interest-fw-input"}/>}
        />
    );
}