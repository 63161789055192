module.exports = `<!DOCTYPE html>
<html>
<head>
	<meta http-equiv="content-type" content="text/html; charset=utf-8"/>
	<title></title>
	<meta name="generator" content="LibreOffice 7.6.3.2 (Linux)"/>
	<meta name="created" content="00:00:00"/>
	<meta name="changed" content="00:00:00"/>
	<style type="text/css">
		@page { size: 8.5in 11in; margin: 1in }
		p { line-height: 115%; text-align: left; orphans: 2; widows: 2; margin-bottom: 0.1in; direction: ltr; background: transparent }
	</style>
</head>
<body lang="en-US" link="#000080" vlink="#800000" dir="ltr"><p align="center" style="line-height: 100%; margin-bottom: 0.08in">
<font face="Times New Roman, serif"><font size="1" style="font-size: 12pt">TÉRMINOS
Y CONDICIONES GENERALES DE TREEMBO</font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0.08in"><font face="Times New Roman, serif"><font size="1" style="font-size: 12pt">POR
MEDIO DEL PRESENTE, GREENBOOK SOCIAL NETWORK, S.A.P.I. DE C.V.
(REFERIDO SUCESIVAMENTE COMO “TREEMBO”), PONE A DISPOSICIÓN DE
LOS USUARIOS DE LA PLATAFORMA/APP DEL PROVEEDOR SUCESIVAMENTE E
INDISTINTAMENTE REFERIDA COMO LA “PLATAFORMA” O “APP”
(REFERIDOS INDISTINTAMENTE EN LO SUCESIVO COMO LOS “USUARIOS” O
EL “USUARIO”), TODOS CONJUNTAMENTE REFERIDOS COMO LAS “PARTES”,
LOS SIGUIENTES TÉRMINOS Y CONDICIONES GENERALES QUE REGIRÁN LA
RELACIÓN ENTRE LAS MISMAS, EN CONSECUENCIA, LAS PARTES SE SUJETAN A
LAS SIGUIENTES DECLARACIONES, Y CLÁUSULAS.</font></font></p>
<p align="center" style="line-height: 100%; margin-bottom: 0.08in"><font face="Times New Roman, serif"><font size="1" style="font-size: 12pt">D
E C L A R A C I O N E S</font></font></p>
<p align="justify" style="line-height: 100%; text-indent: 0.5in; margin-top: 0.17in; margin-bottom: 0.25in">
<font face="Times New Roman, serif"><font size="1" style="font-size: 12pt">TREEMBO,
declara que:</font></font></p>
<ul>
	<li><p align="justify" style="line-height: 100%; orphans: 2; widows: 2; margin-bottom: 0in">
	<font face="Times New Roman, serif"><font size="1" style="font-size: 12pt">Su
	nombre comercial es Treembo.</font></font></p></li>
	<li><p align="justify" style="line-height: 100%; orphans: 2; widows: 2; margin-bottom: 0in">
	<font face="Times New Roman, serif"><font size="1" style="font-size: 12pt">Que
	identifica los servicios que presta a los USUARIOS a través de su
	marca Treembo.</font></font></p></li>
	<li><p align="justify" style="line-height: 100%; orphans: 2; widows: 2; margin-bottom: 0in">
	<font face="Times New Roman, serif"><font size="1" style="font-size: 12pt">Que
	cuenta con domicilio ubicado en *.</font></font></p></li>
	<li><p align="justify" style="line-height: 100%; orphans: 2; widows: 2; margin-bottom: 0in">
	<font face="Times New Roman, serif"><font size="1" style="font-size: 12pt">Que
	cuenta con registro federal de contribuyente GSN1612148N8.</font></font></p></li>
	<li><p align="justify" style="line-height: 100%; orphans: 2; widows: 2; margin-bottom: 0in">
	<font face="Times New Roman, serif"><font size="1" style="font-size: 12pt">Que
	los servicios que prestará a los USUARIOS se encuentran en
	cumplimiento de las disposiciones aplicables de Ley Federal de
	Protección al Consumidor, la Norma Mexicana-COE-001-SCFI-2018 y
	demás normas conducentes de la legislación mexicana. </font></font>
	</p></li>
	<li><p align="justify" style="line-height: 100%; orphans: 2; widows: 2; margin-bottom: 0in">
	<font face="Times New Roman, serif"><font size="1" style="font-size: 12pt">Que
	por medio del presente pone a disposición de los USUARIOS los
	términos y condiciones que regirán la relación entre las PARTES
	en virtud del uso de la Plataforma. </font></font>
	</p></li>
	<li><p align="justify" style="line-height: 100%; orphans: 2; widows: 2; margin-bottom: 0in">
	<font face="Times New Roman, serif"><font size="1" style="font-size: 12pt">Que
	en virtud de la prestación de estos servicios provee un espacio
	virtual para que los Usuarios puedan disfrutar de diversos
	servicios.</font></font></p></li>
	<li><p align="justify" style="line-height: 100%; orphans: 2; widows: 2; margin-bottom: 0in">
	<font face="Times New Roman, serif"><font size="1" style="font-size: 12pt">Que
	establece como medio de contacto su correo electrónico * y hace
	constar que opera a través de su app “Treembo” descargable en
	dispositivos Ios y Android *.</font></font></p></li>
	<li><p align="justify" style="line-height: 100%; orphans: 2; widows: 2; margin-bottom: 0in">
	<font face="Times New Roman, serif"><font size="1" style="font-size: 12pt">Los
	USUARIOS, al momento de suscribir los presentes términos y
	condiciones o al utilizar la PLATAFORMA, reconocen y declaran que:</font></font></p></li>
	<li><p align="justify" style="line-height: 100%; orphans: 2; widows: 2; margin-bottom: 0in">
	<font face="Times New Roman, serif"><font size="1" style="font-size: 12pt">Son
	personas físicas, mayores de edad, con pleno uso y goce de sus
	facultades. O en su caso, que son personas morales debidamente
	constituidas y representadas legítimamente por su representante. </font></font>
	</p></li>
	<li><p align="justify" style="line-height: 100%; orphans: 2; widows: 2; margin-bottom: 0in">
	<font face="Times New Roman, serif"><font size="1" style="font-size: 12pt">Reconocen
	que TREEMBO es un proveedor que ofrece a través de su plataforma un
	espacio para que los Usuarios puedan disfrutar de diversos servicios
	descritos más adelante.</font></font></p></li>
	<li><p align="justify" style="line-height: 100%; orphans: 2; widows: 2; margin-bottom: 0in">
	<font face="Times New Roman, serif"><font size="1" style="font-size: 12pt">La
	información que el USUARIO entregue a TREEMBO y/o publique en la
	PLATAFORMA deberá ser siempre legítima y veraz. </font></font>
	</p></li>
	<li><p align="justify" style="line-height: 100%; orphans: 2; widows: 2; margin-bottom: 0in">
	<font face="Times New Roman, serif"><font size="1" style="font-size: 12pt">Serán
	completamente responsables por la legitimidad y veracidad de la
	información que compartan a TREEMBO o que compartan en la
	PLATAFORMA. </font></font>
	</p></li>
	<li><p align="justify" style="line-height: 100%; orphans: 2; widows: 2; margin-bottom: 0in">
	<font face="Times New Roman, serif"><font size="1" style="font-size: 12pt">Que
	al momento de suscribir los presentes términos y condiciones, o
	usar la PLATAFORMA, están conformes y de acuerdo con la totalidad
	de su contenido. </font></font>
	</p></li>
	<li><p align="justify" style="line-height: 100%; orphans: 2; widows: 2; margin-bottom: 0in">
	<font face="Times New Roman, serif"><font size="1" style="font-size: 12pt">Que
	aceptan entregar la información solicitada por TREEMBO para el
	Registro del Usuario en la PLATAFORMA.</font></font></p></li>
	<li><p align="justify" style="line-height: 100%; orphans: 2; widows: 2; margin-bottom: 0.25in">
	<font face="Times New Roman, serif"><font size="1" style="font-size: 12pt">Que
	no han sufrido ningún vicio del consentimiento al consentir los
	presentes términos y condiciones, y, por lo tanto, renuncian a
	ejercer cualquier acción judicial, administrativa y penal, en razón
	de error, lesión, miedo, violencia, y cualquier vicio del
	consentimiento previsto por la legislación aplicable.</font></font></p></li>
</ul>
<p align="justify" style="line-height: 100%; margin-bottom: 0.08in"><font face="Times New Roman, serif"><font size="1" style="font-size: 12pt">Las
PARTES conformes con las declaraciones anteriores, reconocen,
suscriben y están de acuerdo con las siguientes</font></font></p>
<p align="center" style="line-height: 100%; margin-bottom: 0.08in"><font face="Times New Roman, serif"><font size="1" style="font-size: 12pt">CLÁUSULAS</font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0.08in"><font face="Times New Roman, serif"><font size="1" style="font-size: 12pt"><b>1.
TREEMBO</b></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0.08in"><font face="Times New Roman, serif"><font size="1" style="font-size: 12pt">Treembo
es una compañía que ofrece principalmente servicios vinculados al
comercio electrónico de productos relacionados con el sector
económico primario, agrícola, pecuario y acuícola.</font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0.08in"><font face="Times New Roman, serif"><font size="1" style="font-size: 12pt">Los
presentes Términos y Condiciones Generales aplican para los
siguientes servicios ofrecidos por Treembo:</font></font></p>
<ul>
	<li><p align="justify" style="line-height: 100%; margin-bottom: 0in">
	<font face="Times New Roman, serif"><font size="1" style="font-size: 12pt">El
	Marketplace: es una plataforma de comercio electrónico donde los
	Usuarios pueden vender y comprar productos usando distintas
	soluciones de pago y envío. </font></font>
	</p></li>
	<li><p align="justify" style="line-height: 100%; margin-bottom: 0in">
	<font face="Times New Roman, serif"><font size="1" style="font-size: 12pt">Treembo
	Publicidad: se ofrece a Usuarios realizar promociones y anuncios en
	la plataforma de Treembo. </font></font>
	</p></li>
	<li><p align="justify" style="line-height: 100%; margin-bottom: 0in">
	<font face="Times New Roman, serif"><font size="1" style="font-size: 12pt">Treembo
	Blog: la plataforma comparte entradas publicadas por diversos
	medios y fuentes de información relacionadas con el sector
	económico agrícola. </font></font>
	</p></li>
</ul>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0.08in"><font face="Times New Roman, serif"><font size="1" style="font-size: 12pt">Para
poder operar en la plataforma de Treembo todas los Usuarios deberán
aceptar los Términos y Condiciones, los anexos y el Aviso de
Privacidad.</font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0.08in"><font face="Times New Roman, serif"><font size="1" style="font-size: 12pt">Cada
Usuario es responsable de los datos personales que brinda al momento
de registrarse y se obliga a mantenerlos actualizados. Además, es el
único responsable del uso y resguardo de su contraseña. </font></font>
</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0.08in"><font face="Times New Roman, serif"><font size="1" style="font-size: 12pt">En
algunos casos, podremos cobrar una tarifa por el uso de los servicios
referidos anteriormente, que el Usuario se compromete a pagar.</font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0.08in"><font face="Times New Roman, serif"><font size="1" style="font-size: 12pt">Los
servicios que ofrece Treembo en el sitio web treembo.com y su
aplicación móvil (en lo sucesivo: la “Plataforma”) están
diseñados para formar un ecosistema que permita a las personas
vender, comprar, pagar y enviar productos relacionados con el sector
agrícola.</font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0.08in"><font face="Times New Roman, serif"><font size="1" style="font-size: 12pt"><b>2.
TÉRMINOS Y CONDICIONES</b></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0.08in"><font face="Times New Roman, serif"><font size="1" style="font-size: 12pt">Estos
términos y condiciones y los anexos que explican los servicios de la
Plataforma (de ahora en más: “Términos y Condiciones”) regulan
la relación entre Treembo y las  personas que usan sus servicios
(“USUARIOS”). </font></font>
</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0.08in"><font face="Times New Roman, serif"><font size="1" style="font-size: 12pt">Los
USUARIOS aceptan estos Términos y Condiciones desde el momento en
que se registran en la Plataforma o usan la Plataforma.</font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0.08in"><font face="Times New Roman, serif"><font size="1" style="font-size: 12pt">Cuando
debamos hacer cambios importantes en nuestros servicios, publicaremos
las modificaciones con 10 días corridos de anticipación para que
los Usuarios puedan revisarlas y seguir usando la Plataforma. En
ningún caso afectarán las operaciones que ya hayan finalizado.</font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0.08in"><font face="Times New Roman, serif"><font size="1" style="font-size: 12pt">Los
Usuarios que no tengan obligaciones pendientes con Treembo o con
otros Usuarios, podrán finalizar la relación con Treembo cancelando
su cuenta.</font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0.08in"><font face="Times New Roman, serif"><font size="1" style="font-size: 12pt"><b>3.
CAPACIDAD</b></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0.08in"><font face="Times New Roman, serif"><font size="1" style="font-size: 12pt">Podrán
usar nuestros servicios las personas mayores de edad que tengan
capacidad legal para contratar. </font></font>
</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0.08in"><font face="Times New Roman, serif"><font size="1" style="font-size: 12pt">Quien
use la Plataforma en representación de una empresa deberá tener
capacidad para contratar a nombre de ella. Además, para poder usar
la cuenta, el Usuario debe encontrarse activo.</font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0.08in"><font face="Times New Roman, serif"><font size="1" style="font-size: 12pt"><b>4.
REGISTRO Y CUENTA</b></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0.08in"><font face="Times New Roman, serif"><font size="1" style="font-size: 12pt">Quien
quiera usar nuestros servicios, deberá completar el formulario de
registro con los datos que le sean requeridos. Al completarlo, se
compromete a hacerlo de manera exacta, precisa y verdadera y a
mantener sus datos siempre actualizados. El Usuario será el único
responsable de la certeza de sus datos de registro. Sin perjuicio de
la información brindada en el formulario, podremos solicitar y/o
consultar información adicional para corroborar la identidad del
Usuario. </font></font>
</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0.08in"><font face="Times New Roman, serif"><font size="1" style="font-size: 12pt">Una
vez que el USUARIO ha concluido la creación de su cuenta y ha
llenado los campos requeridos con su información personal, deberá
de corroborar su identidad presentando la siguiente documentación,
según sea su caso:</font></font></p>
<ul>
	<li><p align="justify" style="line-height: 100%; margin-bottom: 0in">
	<font face="Times New Roman, serif"><font size="1" style="font-size: 12pt">Personas
	físicas: Deberán subir a la plataforma la imagen de su credencial
	para votar expedida por el Instituto Nacional Electoral (INE / IFE).</font></font></p></li>
	<li><p align="justify" style="line-height: 100%; margin-bottom: 0in">
	<font face="Times New Roman, serif"><font size="1" style="font-size: 12pt">Personas
	morales: Deberán subir a la plataforma la carátula de su Registro
	Federal de Contribuyentes (RFC) y la imagen de la credencial para
	votar expedida por el Instituto Nacional Electoral (INE / IFE) del
	representante legal de la empresa.</font></font></p></li>
</ul>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0.08in"><font face="Arial Unicode MS, serif"><font size="1" style="font-size: 12pt">Posteriormente,
una vez que se ha corroborado la veracidad de la información
personal con la documentación exhibida, la cuenta del usuario tendrá
el carácter de “Cuenta Verificada”. Las cuentas verificadas
contendrán a un lado del nombre del usuario un check o palomita: ✓.</font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0.08in"><font face="Times New Roman, serif"><font size="1" style="font-size: 12pt">La
cuenta es personal, única e intransferible, es decir que bajo ningún
concepto se podrá vender o ceder a otra persona. Se accede a ella
con la clave personal de seguridad que haya elegido y que deberá
mantener bajo estricta confidencialidad. En cualquier caso, el
Usuario será el único responsable por las operaciones que se
realicen en su cuenta. En caso de detectar un uso no autorizado de su
cuenta, deberá notificar de forma inmediata y fehaciente a Treembo.</font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0.08in"><font face="Times New Roman, serif"><font size="1" style="font-size: 12pt">Podremos
rechazar una solicitud de registro o bien cancelar un registro ya
aceptado, sin que esto genere derecho a un resarcimiento. No podrán
registrarse nuevamente en el Sitio los Usuarios que hayan sido
inhabilitados previamente. Tampoco podrán registrarse quienes estén
incluidos o relacionados a personas incluidas en listas nacionales o
internacionales de sanciones, relacionados con delitos de lavado de
dinero o financiamiento al terrorismo.  </font></font>
</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0.08in"><font face="Times New Roman, serif"><font size="1" style="font-size: 12pt">Además,
en caso de detectar el uso de más de una cuenta, podremos aplicar
retenciones, débitos y/o cualquier otra medida si consideramos que
ese accionar puede perjudicar al resto de las personas que usan la
Plataforma, más allá de las sanciones que pudieran corresponder. </font></font>
</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0.08in"><font face="Times New Roman, serif"><font size="1" style="font-size: 12pt">A
la información proporcionada por los USUARIOS se le podrá aplicar
filtros y/o algoritmos de búsqueda para hacerles llegar
recomendaciones respecto a contactos, publicidad, empleos y demás
contenido que pudiera resultarles de interés. De igual forma, dicha
información podrá ser ofrecida a terceros para fines comerciales
exclusivamente. </font></font>
</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0.08in"><font face="Times New Roman, serif"><font size="1" style="font-size: 12pt">La
información y la documentación proporcionada por el usuario será
empleada como una herramienta que impida la creación de más de una
cuenta por usuario, así como la suplantación de identidad. De igual
manera, dicha información y documentación estará sujeta al
tratamiento previsto en el Aviso de Privacidad de Treembo, mismo que
se encuentra disponible en la PLATAFORMA.</font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0.08in"><font face="Times New Roman, serif"><font size="1" style="font-size: 12pt">La
verificación a la que se hace mención en este apartado, implica
únicamente que la información proporcionada por el usuario coincide
con la documentación exhibida y que su identidad es real, sin
embargo, esto no debe interpretarse como que en Treembo tenemos
conocimiento de los antecedentes del usuario ni de la fiabilidad de
las actividades y/o negocios que realiza.</font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0.08in"><font face="Times New Roman, serif"><font size="1" style="font-size: 12pt"><b>5.
PRIVACIDAD DE DATOS</b></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0.08in"><font face="Times New Roman, serif"><font size="1" style="font-size: 12pt">En
Treembo hacemos un uso responsable de la información personal,
protegiendo la privacidad de los Usuarios que nos confiaron sus datos
y tomando las medidas necesarias para garantizar la seguridad en
nuestra Plataforma. Para mayor detalle puedes consultar nuestro Aviso
de Privacidad. </font></font>
</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0.08in"><font face="Times New Roman, serif"><font size="1" style="font-size: 12pt"><b>6.
SANCIONES</b></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0.08in"><font face="Times New Roman, serif"><font size="1" style="font-size: 12pt">En
caso que el Usuario incumpliera una ley o los Términos y
Condiciones, podremos advertir, suspender, restringir o inhabilitar
temporal o definitivamente su cuenta, sin perjuicio de otras
sanciones que se establezcan en las reglas de uso particulares de los
servicios de Treembo. </font></font>
</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0.08in"><font face="Times New Roman, serif"><font size="1" style="font-size: 12pt"><b>7.
NO RELACIÓN LABORAL Y AUSENCIA DE SUBORDINACIÓN</b></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0.08in"><font face="Times New Roman, serif"><font size="1" style="font-size: 12pt">Las
relaciones jurídicas con los Usuarios son de naturaleza mercantil y
no se podrá considerar en ningún momento como una relación
laboral. De igual manera, para todos los efectos legales a que haya
lugar, las PARTES reconocen que TREEMBO no tiene relación laboral
con los Usuarios, y por lo tanto no está obligado al cumplimiento de
las disposiciones inherentes a tal relación laboral, incluyendo el
pago de salarios, seguridad social e impuestos, trato con su
sindicato, entre otras obligaciones que sean consecuencia de dicha
relación laboral. </font></font>
</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0.08in"><font face="Times New Roman, serif"><font size="1" style="font-size: 12pt"><b>8.
DE LA PLATAFORMA</b></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0.08in"><font face="Times New Roman, serif"><font size="1" style="font-size: 12pt">En
caso de fallas, interrupción, falta de accesibilidad en la
PLATAFORMA de TREEMBO, este será responsable de solucionar el
determinado problema o falla a la brevedad. </font></font>
</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0.08in"><font face="Times New Roman, serif"><font size="1" style="font-size: 12pt"><b>9.
MECANISMO DE NOTIFICACIONES O MEDIOS DE COMUNICACIÓN</b></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0.08in"><font face="Times New Roman, serif"><font size="1" style="font-size: 12pt">Las
PARTES</font></font><font face="Times New Roman, serif"><font size="1" style="font-size: 12pt"><b>
</b></font></font><font face="Times New Roman, serif"><font size="1" style="font-size: 12pt">establecen
que cualquier notificación que se deba realizar en virtud del
presente deberá ser en forma escrita y podrá hacerse a través de
correo electrónico (e-mail) o mensajería whatsapp. </font></font>
</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0.08in"> 
</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0.08in"><font face="Times New Roman, serif"><font size="1" style="font-size: 12pt">Las
PARTES establecen como su domicilio y dirección electrónica para
oír y recibir todo tipo de notificaciones y comunicados relacionados
con el presente Contrato, el siguiente:</font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0.08in"><font face="Times New Roman, serif"><font size="1" style="font-size: 12pt"><b>TREEMBO</b></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0.08in"><font face="Times New Roman, serif"><font size="1" style="font-size: 12pt"><b>	Dirección:
*</b></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0.08in"><font face="Times New Roman, serif"><font size="1" style="font-size: 12pt"><b>	Correo
electrónico: *</b></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0.08in"><font face="Times New Roman, serif"><font size="1" style="font-size: 12pt"><b>EL
USUARIO</b></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0.08in"><font face="Times New Roman, serif"><font size="1" style="font-size: 12pt"><b>	Dirección:
La dirección que sea designada por este en la PLATAFORMA.</b></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0.08in"><font face="Times New Roman, serif"><font size="1" style="font-size: 12pt"><b>	Correo
electrónico: El correo electrónico designado por este en la
PLATAFORMA.</b></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0.08in"><font face="Times New Roman, serif"><font size="1" style="font-size: 12pt">Cualquiera
de las PARTES podrá notificar a la otra de un nuevo domicilio o
dirección electrónica al que deban dirigirse todos los avisos y
notificaciones relacionados con el presente Contrato, mediante simple
aviso por escrito, enviado al domicilio y/o dirección electrónica
de la otra, con una anticipación mínima de 10 (diez) días hábiles
a aquél en el que deba producirse el cambio de domicilio y/o
dirección electrónica, en caso contrario, cualquier aviso o
comunicación enviado a la otra Parte al último domicilio y/o
dirección electrónica registrado surtirá plenos efectos.</font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0.08in"><font face="Times New Roman, serif"><font size="1" style="font-size: 12pt"><b>10.
PAGOS DE CONTRIBUCIONES</b></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0.08in"><font face="Times New Roman, serif"><font size="1" style="font-size: 12pt">Todos
los impuestos, contribuciones y demás cargas fiscales que se
originen por la celebración de los presentes términos y condiciones
o durante su vigencia, deberán cubrirse por la parte que en los
términos de las leyes y demás disposiciones fiscales resulte
obligada a ello. </font></font>
</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0.08in"><font face="Times New Roman, serif"><font size="1" style="font-size: 12pt"><b>11.
MECANISMOS DE ACLARACIONES Y RECLAMACIONES</b></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0.08in"><font face="Times New Roman, serif"><font size="1" style="font-size: 12pt">TREEMBO
establece que cualquier aclaración y reclamación de los USUARIOS
exclusivamente con relación a los servicios de TREEMBO que realiza a
través de la PLATAFORMA, podrá ser presentada por los mismos a
través de los medios de comunicación establecidos por la cláusula
novena anterior, o en su caso, por medio de la PLATAFORMA. Serán
atendidas en días hábiles, en horarios de las 9:00 hrs a las 14:00
hrs y de las 16:00 hrs a las 18:00 hrs. El tiempo de respuesta será
de máximo 5 (cinco) días hábiles.  </font></font>
</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0.08in"><font face="Times New Roman, serif"><font size="1" style="font-size: 12pt"><b>12.
CASO FORTUITO Y FUERZA MAYOR</b></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0.08in"><font face="Times New Roman, serif"><font size="1" style="font-size: 12pt">Los
USUARIOS reconocen que el TREEMBO no será responsable por el
incumplimiento o retraso en el cumplimiento de sus obligaciones,
consecuencia de caso fortuito y fuerza mayor. </font></font>
</p>
<p align="justify" style="margin-bottom: 0in"><font face="Times New Roman, serif"><font size="1" style="font-size: 12pt"><b>13.
NORMATIVA APLICABLE, JURISDICCIÓN Y COMPETENCIA</b></font></font></p>
<p align="justify" style="margin-bottom: 0in"><font face="Times New Roman, serif"><font size="1" style="font-size: 12pt">Para
la interpretación y cumplimiento de los presentes términos y
condiciones, las PARTES se someten a la jurisdicción y competencia
de los tribunales competentes ubicados en la zona metropolitana de
Guadalajara, Jalisco, renunciando a cualquier otro fuero que pudiera
corresponderles por razón de sus domicilios presentes o futuros. </font></font>
</p>
<p align="justify" style="margin-bottom: 0in"><font face="Times New Roman, serif"><font size="1" style="font-size: 12pt">Por
último, se reconocen las atribuciones que la Procuraduría Federal
del Consumidor tiene en virtud de la Ley Federal de Protección al
Consumidor.</font></font></p>
<p align="justify" style="margin-bottom: 0in"><br/>

</p>
<p align="justify" style="margin-bottom: 0in"><font face="Times New Roman, serif"><font size="1" style="font-size: 12pt"><b>14.
MODIFICACIONES A LOS TÉRMINOS Y CONDICIONES. </b></font></font><font face="Times New Roman, serif"><font size="1" style="font-size: 12pt">TREEMBO
podrá unilateralmente modificar lo descrito por los presentes
términos y condiciones, notificando a los USUARIOS con por lo menos
3 (tres) días naturales de anticipación, los USUARIOS deberán
externar su consentimiento y suscribir su conformidad con los nuevos
términos y condiciones para seguir usando la PLATAFORMA, o en su
caso, externar su desacuerdo y cesar el uso de la PLATAFORMA. En caso
de que los USUARIOS omitan realizar en tiempo y forma la suscripción
y firma de los mismos, el PROVEEDOR INTERMEDIARIO, operará su
PLATAFORMA de acuerdo a los términos y condiciones vigentes, mismos
que serán completamente válidos y efectivos entre las PARTES. </font></font>
</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0.08in"><font face="Times New Roman, serif"><font size="1" style="font-size: 12pt"><br/>

</font></font><font face="Times New Roman, serif"><font size="1" style="font-size: 12pt"><b>15.
RESPONSABILIDAD</b></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0.08in"><font face="Times New Roman, serif"><font size="1" style="font-size: 12pt">TREEMBO
será responsable por cualquier defecto en la prestación de su
servicio, en la medida en que le sea imputable y con el alcance
previsto en las leyes vigentes. </font></font>
</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0.08in"><font face="Times New Roman, serif"><font size="1" style="font-size: 12pt"><br/>

</font></font><font face="Times New Roman, serif"><font size="1" style="font-size: 12pt"><b>16.
CONTRAPRESTACIONES Y TARIFAS</b></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0.08in"><font face="Times New Roman, serif"><font size="1" style="font-size: 12pt">TREEMBO
podrá cobrar por sus servicios y el USUARIO se compromete a pagarlos
a tiempo. </font></font>
</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0.08in"><font face="Times New Roman, serif"><font size="1" style="font-size: 12pt">Podremos
modificar o eliminar las contraprestaciones en cualquier momento con
el debido preaviso establecido en la cláusula 2 de estos Términos y
Condiciones. De la misma manera, podremos modificar las tarifas
temporalmente por promociones en favor de los USUARIOS. </font></font>
</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0.08in"><font face="Times New Roman, serif"><font size="1" style="font-size: 12pt">Para
conocer el detalle de las tarifas de cada servicio, los USUARIOS
deberán consultar los términos y condiciones correspondientes. </font></font>
</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0.08in"><font face="Times New Roman, serif"><font size="1" style="font-size: 12pt">En
todos los casos se emitirá la factura de conformidad con los datos
fiscales que las personas tengan cargados en su cuenta. </font></font>
</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0.08in"><font face="Times New Roman, serif"><font size="1" style="font-size: 12pt"><b>17.
PROPIEDAD INTELECTUAL</b></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0.08in"><font face="Times New Roman, serif"><font size="1" style="font-size: 12pt">TREEMBO
y/o sus sociedades relacionadas son propietarias de todos los
derechos de propiedad intelectual sobre sus sitios, todo su
contenido, servicios, productos, marcas, nombres comerciales, logos,
diseños, imágenes, frases publicitarias, derechos de autor,
dominios, programas de computación, códigos, desarrollos, software,
bases de datos, información, tecnología, patentes y modelos de
utilidad, diseños y modelos industriales, secretos comerciales,
entre otros (“Propiedad Intelectual”) y se encuentran protegidos
por leyes nacionales e internacionales.</font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0.08in"><font face="Times New Roman, serif"><font size="1" style="font-size: 12pt">Aunque
TREEMBO otorga permiso para usar sus productos y servicios conforme a
lo previsto en los Términos y Condiciones, esto no implica una
autorización para usar su  Propiedad Intelectual, excepto
consentimiento previo y expreso de TREEMBO y/o sus sociedades
vinculadas. En cualquier caso, los usuarios vendedores que usen
dichos productos y servicios no podrán utilizar la Propiedad
Intelectual de TREEMBO de una manera que cause confusión en el
público y deberán llevar a cabo su actividad comercial bajo una
marca o nombre comercial propio y distintivo, que no resulte
confundible con la marca TREEMBO.</font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0.08in"><font face="Times New Roman, serif"><font size="1" style="font-size: 12pt">Está
prohibido usar nuestros productos o servicios para fines ilegales,
realizar cualquier tipo de ingeniería inversa u obras derivadas,
utilizar herramientas de búsqueda o de extracción de datos y
contenidos de nuestra plataforma para su reutilización y/o crear
bases de datos propias que incluyan en todo o en parte nuestro
contenido sin nuestra expresa autorización. Está también prohibido
el uso indebido, sin autorización y/o contrario a la normativa
vigente y/o que genere confusión o implique uso denigratorio y/o que
le cause perjuicio, daños o pérdidas a TREEMBO y/o a sus sociedades
relacionadas. La utilización de los productos y servicios de TREEMBO
tampoco implica la autorización para usar propiedad intelectual de
terceros que pueda estar involucrada, cuyo uso quedará bajo
exclusiva responsabilidad del usuario. <br/>
En caso que un USUARIO o
cualquier publicación infrinja la Propiedad Intelectual de TREEMBO o
de terceros, TREEMBO podrá remover dicha publicación total o
parcialmente), sancionar al USUARIO conforme a lo previsto en estos
Términos y Condiciones y ejercer las acciones extrajudiciales y/o
judiciales correspondientes.</font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0.08in">
<font face="Times New Roman, serif"><font size="1" style="font-size: 12pt"><br/>

</font></font><font face="Times New Roman, serif"><font size="1" style="font-size: 12pt"><b>18.
INDEMNIDAD</b></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0.08in"><font face="Times New Roman, serif"><font size="1" style="font-size: 12pt">El
USUARIO mantendrá indemne a TREEMBO y sus partes relacionadas, así
como a quienes la dirigen, suceden, administran, representan y/o
trabajan en ellas, por cualquier reclamo administrativo o judicial
iniciado por otros USUARIOS, terceros o por cualquier Organismo,
relacionado con sus actividades en la PLATAFORMA.</font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0.08in"><font face="Times New Roman, serif"><font size="1" style="font-size: 12pt"><br/>
En
virtud de esa responsabilidad, podrán realizar compensaciones,
retenciones u otras medidas necesarias para la reparación de
pérdidas, daños y perjuicios, cualquiera sea su naturaleza.</font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0.08in"><font face="Times New Roman, serif"><font size="1" style="font-size: 12pt"><b>19.
ANEXOS</b></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0.08in"><font face="Times New Roman, serif"><font size="1" style="font-size: 12pt">Además
de estos Términos y Condiciones, cada servicio de la Plataforma
tiene sus propias reglas de uso:  </font></font>
</p>
<ul>
	<li><p align="justify" style="line-height: 100%; margin-bottom: 0in">
	<font face="Times New Roman, serif"><font size="1" style="font-size: 12pt">Marketplace</font></font></p></li>
	<li><p align="justify" style="line-height: 100%; margin-bottom: 0in">
	<font face="Times New Roman, serif"><font size="1" style="font-size: 12pt">Treembo
	Publicidad</font></font></p></li>
	<li><p align="justify" style="line-height: 100%; margin-bottom: 0in">
	<font face="Times New Roman, serif"><font size="1" style="font-size: 12pt">Treembo
	Blog </font></font>
	</p></li>
</ul>
<p align="center" style="line-height: 100%; margin-bottom: 0.08in"><font face="Times New Roman, serif"><font size="1" style="font-size: 12pt">C
L A U S U L A S </font></font>
</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0.08in"><font face="Times New Roman, serif"><font size="1" style="font-size: 12pt"><b>PRIMERA.
USO DE LA PLATAFORMA TREEMBO.</b></font></font><font face="Times New Roman, serif"><font size="1" style="font-size: 12pt">
Los USUARIOS reconocen que los servicios que prestará el PROVEEDOR
INTERMEDIARIO son única y exclusivamente de intermediación pues a
través de su PLATAFORMA, el USUARIO podrá solicitar la prestación
de bienes y/o servicios de las Empresas promovidas y ofertadas en la
PLATAFORMA, en el entendido que será directamente con las Empresas a
quienes los USUARIOS contraten y hagan el pago correspondiente.</font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0.08in"><font face="Times New Roman, serif"><font size="1" style="font-size: 12pt">Previo
escoger algún servicio y/o producto de las Empresas, el PROVEEDOR
INTERMEDIARIO ayudará al USUARIO a determinar su determinado perfil
de riesgo crediticio a través de una encuesta, para que el USUARIO
se conozca a mayor profundidad antes de adquirir algún producto y/o
servicio de parte de las Empresas.</font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0.08in"><font face="Times New Roman, serif"><font size="1" style="font-size: 12pt">Una
vez elegido el determinado servicio y/o producto, el PROVEEDOR
INTERMEDIARIO informará los datos e información necesaria para que
el USUARIO y la Empresa correspondiente puedan contactarse, para
efectos de que, en su caso, el USUARIO contrate directamente con la
EMPRESA la prestación del servicio o compra del producto. </font></font>
</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0.08in"><font face="Times New Roman, serif"><font size="1" style="font-size: 12pt"><b>SEGUNDA.
CONTRAPRESTACIÓN.</b></font></font><font face="Times New Roman, serif"><font size="1" style="font-size: 12pt">
Los USUARIOS reconocen que estos no pagarán contraprestación alguna
a favor de El PROVEEDOR INTERMEDIARIO pues son las Empresas quienes
en todo caso, en virtud de la relación contractual de corretaje que
tienen con TREEMBO, pagarán la recompensa, honorario o comisión
respectiva acordada a favor del PROVEEDOR INTERMEDIARIO, en los
términos acordados entre TREEMBO y las Empresas. </font></font>
</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0.08in"><font face="Times New Roman, serif"><font size="1" style="font-size: 12pt"><b>TERCERA.
DE LA EXIMICIÓN DE RESPONSABILIDAD.</b></font></font><font face="Times New Roman, serif"><font size="1" style="font-size: 12pt">
Las PARTES reconocen y acuerdan que el PROVEEDOR INTERMEDIARIO no es
responsable de la legitimidad y veracidad de toda información
entregada por las Empresas con relación a sus servicios y productos
promocionados en la PLATAFORMA. Además, TREEMBO no será responsable
por los resultados, incumplimientos, retrasos en el cumplimiento e
indebido cumplimiento de parte de las Empresas respecto la prestación
de servicios y/o bienes que sean ofrecidos en la PLATAFORMA en virtud
del contrato de corretaje celebrado con el PROVEEDOR INTERMEDIARIO.</font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0.08in"><font face="Times New Roman, serif"><font size="1" style="font-size: 12pt">Adicionalmente
TREEMBO no es responsable de que los servicios y/o productos de las
Empresas cumplan con las disposiciones aplicables de la Ley Federal
de Protección al Consumidor, y demás disposiciones aplicables de la
legislación mexicana.</font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0.08in"><font face="Times New Roman, serif"><font size="1" style="font-size: 12pt">En
consonancia con lo anterior, los USUARIOS aceptan que las Empresas
serán exclusivo responsable de la debida prestación de sus bienes
y/o servicios frente a los USUARIOS. </font></font>
</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0.08in"><font face="Times New Roman, serif"><font size="1" style="font-size: 12pt">En
tal sentido, las Empresas serán las únicas responsables frente a
los USUARIOS y ante toda autoridad administrativa y judicial en caso
de incumplimiento de la prestación de sus bienes y/o servicios,
obligándose a indemnizar al PROVEEDOR INTERMEDIARIO por cualquier
daño y perjuicio causado, gastos, costos de honorarios de abogados,
así como comprometiéndose a sacar a salvo al PROVEEDOR
INTERMEDIARIO de cualquier procedimiento instaurado en su contra,
consecuencia del respectivo incumplimiento.</font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0.08in"><font face="Times New Roman, serif"><font size="1" style="font-size: 12pt">Por
lo tanto, los USUARIOS, renuncian a ejercer acción judicial alguna
en contra del PROVEEDOR INTERMEDIARIO relacionada con el
incumplimiento de las obligaciones a cargo de la Empresa derivado de
la contratación del respectivo servicio y/o producto.  </font></font>
</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0.08in"><font face="Times New Roman, serif"><font size="1" style="font-size: 12pt">El
USUARIO reconoce y acepta que el PROVEEDOR INTERMEDIARIO podrá
revelar su información (respetando lo descrito por su aviso de
privacidad con relación al manejo de los datos personales de los
USUARIOS) exclusivamente a las Empresas, en caso de que el USUARIO
haya elegido solicitar la contratación de sus servicios y/o
productos, para efectos de que la determinada Empresa pueda contactar
al USUARIO y en su caso, celebren el contrato correspondiente. </font></font>
</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0.08in"><font face="Times New Roman, serif"><font size="1" style="font-size: 12pt">Cualquier
reclamación de los servicios y/o productos contratados con las
Empresas por los USUARIOS deberán ser presentadas en contra y ante
las EMPRESAS.</font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0.08in"><font face="Times New Roman, serif"><font size="1" style="font-size: 12pt"><b>CUARTA.
RESPONSABILIDAD DEL PROVEEDOR INTERMEDIARIO Y GARANTÍA.</b></font></font><font face="Times New Roman, serif"><font size="1" style="font-size: 12pt">
El PROVEEDOR INTERMEDIARIO en ningún caso será responsable por la
prestación de los bienes y/o servicios de la Empresa a favor del
CLIENTE, ni tampoco del pago de parte del CLIENTE a favor de la
Empresa. </font></font>
</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0.08in"><font face="Times New Roman, serif"><font size="1" style="font-size: 12pt">El
PROVEEDOR INTERMEDIARIO será responsable de tratar la información,
datos y documentación que reciba de los USUARIOS de acuerdo a lo
establecido por la Ley Federal de Protección de Datos Personales en
Posesión de los Particulares, debiendo poner a disposición de los
USUARIOS el Aviso de Privacidad conducente. </font></font>
</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0.08in"><font face="Times New Roman, serif"><font size="1" style="font-size: 12pt">Los
USUARIOS aceptan y reconocen que su información podrá ser usada por
el PROVEEDOR INTERMEDIARIO únicamente para efectos de poner en
contacto a las Empresas con los USUARIOS para efectos de que en su
caso contraten el servicio y/o producto escogido por los USUARIOS en
la PLATAFORMA. Por lo tanto, los USUARIOS expresan su consentimiento
de que el PROVEEDOR INTERMEDIARIO revele su información a las
Empresas, así como en caso de mandamiento judicial o de autoridades
competentes. </font></font>
</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0.08in"><font face="Times New Roman, serif"><font size="1" style="font-size: 12pt"><b>QUINTA.
AUSENCIA DE PAGOS.</b></font></font><font face="Times New Roman, serif"><font size="1" style="font-size: 12pt">
El PROVEEDOR INTERMEDIARIO jamás recibirá los fondos o pagos de
parte de los USUARIOS por la contratación de los servicios y/o
productos de las Empresas, pues el respectivo contrato será
celebrado entre estas dos últimas partes (los USUARIOS y las
Empresas) y por lo tanto, serán las Empresas quienes reciban los
pagos o fondos de parte de los USUARIOS.  </font></font>
</p>
<p align="justify" style="margin-bottom: 0in"><font face="Times New Roman, serif"><font size="1" style="font-size: 12pt">ÚLTIMA
MODIFICACIÓN *</font></font></p>
<p align="justify" style="margin-bottom: 0in"><font face="Times New Roman, serif"><font size="1" style="font-size: 12pt">LOS
USUARIOS SUSCRIBEN LOS PRESENTES TÉRMINOS Y CONDICIONES EN LA FECHA
DE SU FIRMA, ES DECIR, AL EXTERNAR SU CONSENTIMIENTO EXPRESO MEDIANTE
LA ACEPTACIÓN DE LA CASILLA CORRESPONDIENTE A TRAVÉS DE LA
PLATAFORMA DEL PROVEEDOR INTERMEDIARIO. </font></font>
</p>
<p style="margin-bottom: 0in"><br/>

</p>
</body>
</html>`;