import colors from 'theme/colors';

let sx = {
	mainContainer: {
		backgroundColor: colors.light_background,
		paddingTop: "8px",
		paddingBottom: "80px",
		display: "flex",
		flexDirection: "column",
		alignItems: "center"
	},
	mainGrid: {
		// marginTop: "85px",
		// marginBottom: "48px",
	},
	gridItem: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
	},
	infoCard: {
		position: "relative",
		maxWidth: "846px",
		maxHeight: "574px",
		padding: "0px",
		borderRadius: "24px",
		overflow: "hidden",
		display: "flex",
		flexDirection: "row"
	},
	infoOverlay: {
		display: "flex",
		flexDirection: "column",
		position: "absolute",
		bottom: "50px",
		left: "110px"
	},
	infoOverlayMobile: {
		display: "flex",
		flexDirection: "column",
		position: "absolute",
		bottom: "16px",
		left: "16px"
	}
}

export default sx;