import colors from 'theme/colors';

let sx = {
	mainContainer: {
		// backgroundColor: colors.primary_green,
		display: "flex",
		flexDirection: "column",
		minHeight: "478px",
		marginTop: "100px",
		alignItems: "center"
	},
	rightArrowBox: {
		height: "50px", 
		width: "50px", 
		position: "absolute", 
		right: 0, 
		top: "387px", 
		display: "flex",
		alignItems: "center",
		justifyContent: "center"
	},
	leftArrowBox: {
		height: "50px", 
		width: "50px", 
		position: "absolute", 
		left: 0, 
		top: "387px", 
		display: "flex",
		alignItems: "center",
		justifyContent: "center"
	},
	graySpacer: {
		width: "100%",
		backgroundColor: colors.medium_light_gray,
		height: "1px",
		maxWidth: "1440px",
		marginTop: "80px"
	}
}

export default sx;