import { Box, Button, Container, Grid, Paper, Typography } from '@mui/material'

import Cebolla from "assets/images/banner-products/onion.jpg"
import Tomatillo from "assets/images/banner-products/green_tomato.jpg"
import Jalapeno from "assets/images/banner-products/jalapeno.jpg"
import Aguacate from "assets/images/banner-products/avocado.jpg"
import Frijol from "assets/images/banner-products/bean.jpg"
import Papa from "assets/images/banner-products/potato.jpg"
import Tomate from "assets/images/banner-products/red_tomato.jpg"
import Ejote from "assets/images/banner-products/green_bean.jpg"
import Brocoli from "assets/images/banner-products/broccoli.jpg"
import Zanahoria from "assets/images/banner-products/carrot.jpg"
import Maiz from "assets/images/banner-products/corn.jpg"

import CebollaForm from "assets/images/banner-products/onion_gradient.jpg"
import TomatilloForm from "assets/images/banner-products/green_tomato_gradient.jpg"
import JalapenoForm from "assets/images/banner-products/jalapeno_gradient.jpg"
import AguacateForm from "assets/images/banner-products/avocado_gradient.jpg"
import FrijolForm from "assets/images/banner-products/bean_gradient.jpg"
import PapaForm from "assets/images/banner-products/potato_gradient.jpg"
import TomateForm from "assets/images/banner-products/red_tomato_gradient.jpg"
import EjoteForm from "assets/images/banner-products/green_bean_gradient.jpg"
import BrocoliForm from "assets/images/banner-products/broccoli_gradient.jpg"
import ZanahoriaForm from "assets/images/banner-products/carrot_gradient.jpg"
import MaizForm from "assets/images/banner-products/corn_gradient.jpg"

import { AddRounded } from '@mui/icons-material'
import ProductForm from './banner-components/product-form/product-form'
import { useState } from 'react'
import BannersAppbar from './banner-components/banners-appbar/banners-appbar'

const products = [
	{
		name: "Cebolla",
		img: Cebolla,
		formImg: CebollaForm,
	},
	{
		name: "Tomatillo",
		img: Tomatillo,
		formImg: TomatilloForm,
	},
	{
		name: "Chile Jalapeño",
		img: Jalapeno,
		formImg: JalapenoForm,
	},
	{
		name: "Aguacate",
		img: Aguacate,
		formImg: AguacateForm,
	},
	{
		name: "Frijol",
		img: Frijol,
		formImg: FrijolForm
	},
	{
		name: "Papa",
		img: Papa,
		formImg: PapaForm
	},
	{
		name: "Tomate",
		img: Tomate,
		formImg: TomateForm
	},
	{
		name: "Ejote",
		img: Ejote,
		formImg: EjoteForm
	},
	{
		name: "Brócoli",
		img: Brocoli,
		formImg: BrocoliForm
	},
	{
		name: "Zanahoria",
		img: Zanahoria,
		formImg: ZanahoriaForm
	},
	{
		name: "Maíz",
		img: Maiz,
		formImg: MaizForm
	},
]

const LandingBanners = (props) => {
	const [formOpen, setFormOpen] = useState(false)
	const [selectedProduct, setSelectedProduct] = useState(null)
	const [mode, setMode] = useState("")

	const handleFormOpen = () => {
		setFormOpen(true)
	}

	const handleFormClose = (event, reason) => {
		if (reason && reason == "backdropClick") 
			return;
		setFormOpen(false)
	}

	const selectProductSell = (product) => {
		handleFormOpen()
		setSelectedProduct(product)
		setMode("sell")
	}
	
	const selectProductBuy = (product) => {
		handleFormOpen()
		setSelectedProduct(product)
		setMode("buy")
	}
	
	const productCard = (product) => {
		return <>
            <Grid key={product.name} item xs={6} sm={4} md={3} sx={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
				<Paper sx={{ maxWidth: "216px", borderRadius: "8px", overflow: "hidden" }}>
					<Box sx={{height: "182px",width: "100%", display: "flex", marginBottom: "12px"}}>
						<img 
                            src={product.img} 
                            objectFit="cover"
                            alt=''
                            style={{width: "100%"}}
                        />
					</Box>
					<Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", marginBottom: "12px" }}>
						<Typography variant='title7' color="twilight">
							{product.name}
						</Typography>
					</Box>
					<Box sx={{ display: "flex", flexDirection: "row", justifyContent: "center",  flexWrap: "wrap"}}>
						<Button onClick={()=>selectProductSell(product)} variant='contained' size='small' sx={{ width: "88px", marginRight: "6px",marginLeft: "6px", marginBottom: "12px"}}>
							Vender
						</Button>
						<Button onClick={()=>selectProductBuy(product)} variant='outlined' size='small' sx={{ width: "88px", marginLeft: "6px",marginRight: "6px",marginBottom: "12px"}}>
							Comprar
						</Button>
					</Box>
				</Paper>
			</Grid>
        </>
	}

	return <>
        <Container maxWidth="xl" sx={{ marginBottom: "40px" }}>
			<Grid maxWidth={1440} container spacing={3} sx={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
				<Grid item xs={12} sx={{ display: "flex", flexDirection: "column", alignItems: "center", marginTop: "55px" }}>
					<Box sx={{ maxWidth: "510px", display: "flex", flexDirection: "column", alignItems: "center", marginBottom: "20px" }}>
						<Typography variant='title3' sx={{ textAlign: "center" }}>
							¿Te interesa vender o comprar cosechas en campo?
						</Typography>
					</Box>
					<Box sx={{ maxWidth: "422px", display: "flex", flexDirection: "column", alignItems: "center" }}>
						<Typography variant='body2' sx={{ textAlign: "center" }}>
							Estos son algunos de los productos que actualmente estamos ayudando a comercializar
						</Typography>
					</Box>
				</Grid>
				{products.map((item) => productCard(item))}
				<Grid item xs={12} sx={{ display: "flex", flexDirection: "column", alignItems: "center", marginTop: "40px" }}>
					<Box sx={{ display: "flex", flexDirection: "row", justifyContent: "center", flexWrap: "wrap"}}>
						<Button variant='contained' sx={{ width: "240px", marginRight: "16px",marginLeft: "16px", marginBottom: "16px"}}>
							Vender otro producto <AddRounded color='white'/>
						</Button>
						<Button variant='outlined' sx={{ width: "240px", marginLeft: "16px",marginRight: "16px",marginBottom: "16px"}}>
							Comprar otro producto <AddRounded color='primary'/>
						</Button>
					</Box>
				</Grid>
			</Grid>
			<ProductForm 
				mode={mode} 
				product={selectedProduct} 
				formOpen={formOpen} 
				handleFormOpen={handleFormOpen} 
				handleFormClose={handleFormClose}
			/>
		</Container>
    </>
}

export default LandingBanners;
