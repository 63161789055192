import { Box, Container, Grid, Paper, Typography } from '@mui/material'
import colors from 'theme/colors'
import typography from 'theme/typography'
import InfoA1Img from "assets/images/infoA-1.jpg"
import InfoA2Img from "assets/images/infoA-2.jpg"
import InfoA3Img from "assets/images/infoA-3.jpg"
import sx from './information-sx'
import { ArrowDownwardRounded } from '@mui/icons-material'

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const texts = [
	{
		title: "Comercialización",
		text: (<>Te ayudamos con <b>estrategias y herramientas de marketing digital</b> dirigidas al agro.</>)
	},
	{
		title: "Tree Commerce",
		text: (<>Tree Commerce <b>te permite crear tu tienda virtual</b> en minutos e impulsar tus ganancias.</>)
	},
	{
		title: "Agromarketing",
		text: (<>Las <b>asesorías en comercialización</b> aumentan los ingresos de productores en el 50% de los casos.</>)
	}
]

function InfoCard(img, info, isMobile){
	return (
		<Paper sx={sx.infoCard} elevation={1}>
			<Box sx={sx.infoImage}>
				<img alt='' src={img}/>
			</Box>
			<Typography sx={{...typography.title6, color: colors.twilight}}>
				{info.title}
			</Typography>
			<Typography sx={{...typography.body2}}>
				{info.text}
			</Typography>
		</Paper>
	)
}

export default function Information() {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('md'));
	return (
		<Container maxWidth="xxl" sx={isMobile ? sx.mainContainerMobile : sx.mainContainer}>
			<Grid maxWidth={1440} container sx={sx.mainGrid}>
				<Grid item xs={12} sx={sx.gridItem}>
					<Typography textAlign="center" color="twilight" variant={isMobile ? "title8" : "title7"} sx={{marginBottom: isMobile ? "16px" : "76px" }}>
						Somos la plataforma N°1 de Agronegocios con mayor crecimiento en México
					</Typography>
					<Typography variant={isMobile ? "title5" : "title4"} color="primary">
						Nuestros Servicios
					</Typography>
				</Grid>
				<Grid item xs={12} md={4} sx={sx.gridItem}>
					{InfoCard(InfoA1Img, texts[0], isMobile)}
				</Grid>
				<Grid item xs={12} md={4} sx={sx.gridItem}>
					{InfoCard(InfoA2Img, texts[1], isMobile)}
				</Grid>
				<Grid item xs={12} md={4} sx={sx.gridItem}>
					{InfoCard(InfoA3Img, texts[2], isMobile)}
				</Grid>
				<Grid item xs={12} sx={sx.gridItem}>
					<ArrowDownwardRounded sx={sx.arrowInfo}/>
				</Grid>
			</Grid>
		</Container>
	)
}