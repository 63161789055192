import { useContext, useEffect, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Backdrop, Box, Card, CircularProgress, Grid, Input, InputBase } from '@mui/material';
import colors from 'theme/colors';
import typography from 'theme/typography';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { LandingContext } from 'contexts/landingContext';
import algoliasearch from 'algoliasearch';
import constants from 'common/constants';
import imgSuffix from 'common/image-suffix';
import { shuffle } from 'lodash';
import { redirect } from 'react-router-dom';
import { Close } from '@mui/icons-material';
import percentageDifference from 'utils/percentageDifference';

import Discount from "assets/svgs/product_insignia/discount.svg"
import FreeShipping from "assets/svgs/product_insignia/free_shipping.svg"
import MSI from "assets/svgs/product_insignia/msi.svg"
import Offer from "assets/svgs/product_insignia/offer.svg"
import SameDayShipping from "assets/svgs/product_insignia/same_day_shipping.svg"

const client = algoliasearch(constants.algolia.appId, constants.algolia.searchKey)
const products_index = client.initIndex('firebase_products');
const stores_index = client.initIndex('firebase_stores');
const productsStores_index = client.initIndex('firebase_products_stores');

export default function SearchOverlay(props) {
	// eslint-disable-next-line no-unused-vars
	const [state, dispatch] = useContext(LandingContext);
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

	const [overlay_visible, set_overlay_visible] = useState(false)
	const [loading_products, set_loading_products] = useState(true)
	const [products, set_products] = useState([])
	const [loading_stores, set_loading_stores] = useState(true)
	const [stores, set_stores] = useState([])

	const getProducts = () => {
		try {
			let filters = `ownerType:"store"`
			productsStores_index.search(props.search_text, {
				filters: filters,
				ruleContexts: "client"
			}).then(({ hits }) => {
				let auxProducts = []
				hits.forEach((doc) => {
					auxProducts.push({
						...doc,
						price: parseFloat(doc.price),
					})
				});
				set_loading_products(false)
				set_products([])
				if(props.search_text){
					set_products(prev => [...auxProducts])
				}else{
					set_products(prev => [...shuffle(auxProducts)])
				}
			});
		} catch (error) {
			console.log("Error loading products algolia: ", error)
			set_loading_products(false)
		}
	}

	const getStores = () => {
		try {
			stores_index.search(props.search_text,{
				ruleContexts: "client"
			}).then(({ hits }) => {
				console.log("Store Hits: ", hits.length)
				set_loading_stores(false)
				set_stores([])
				if(props.search_text){
					set_stores(prev => [...hits])
				}else{
					set_stores(prev => [...shuffle(hits)])
				}
			});
		} catch (error) {
			console.log("Error loading stores algolia: ", error)
			set_loading_products(false)
		}
	}

	const handleSearch = () => {
		set_loading_products(true)
		set_loading_stores(true)
		getProducts()
		getStores()
	}

	useEffect(() => {
        const getData = async () => {
            if(props.search_text && props.search_text.length > 2){
				handleSearch()
			}else if(!props.search_text && props.search_visible){
				handleSearch()
			}
        }
        let timer = setTimeout(() => {
            getData()
        }, 500)
        return () => clearTimeout(timer)
    }, [props.search_text])

	useEffect(()=>{
		if(props.search_visible){
			set_overlay_visible(true)
			handleSearch()
		}
	},[props.search_visible])

	const openProduct = async (product) => {
        if(product.objectID){
			console.log("Open product: ", product)
			props.router.navigate(`/loading`, { replace: true })
			setTimeout(() => {
				props.router.navigate(`/product/${product.objectID}`, { replace: true })
				set_overlay_visible(false)
			}, 10);
        }
    }

	const openStore = async (store) => {
		if(store.username){
			console.log("Open store: ", store)
			props.router.navigate(`/loading`, { replace: true })
			setTimeout(() => {
				props.router.navigate(`/store/${store.username}`,{ replace: true })
				set_overlay_visible(false)
			}, 10);
        }
	}

	const closeSearch = () => {
		props.clearSearchText()
		set_overlay_visible(false)
	}

	const _renderProducts = () => {
		if(products.length){
			console.log("Products: ", products)
			return products.map((product, index) => (
				<div onClick={()=>openProduct(product)} key={`prod-algolia-${index}`} style={{paddingLeft: "1rem", paddingRight: "1rem", paddingBottom: "1rem", display: "flex", flexDirection: "row", color: "black", cursor: "pointer", alignItems: "center"}}>
					<div style={{height: "4.5rem", width: "4.5rem", backgroundColor: colors.light_background, borderRadius: "0.5rem", overflow: "hidden"}}>
						{product.images ?
							<img
								alt="img"
								src={product.images[0] + imgSuffix.md}
								style={{display: "flex", width: "100%", objectFit: "cover", height: "100%", aspectRatio: "1:1"}}
							/> 
						: (product.legacy_images ? 
							<img
								alt="img"
								src={product.legacy_images[0] + "480x480"}
								style={{display: "flex", width: "100%", objectFit: "cover", height: "100%", aspectRatio: "1:1"}}
							/> 
						: "")}
					</div>
					<div style={{display: "flex", flexDirection: "column", justifyContent: "center", flex: 1, paddingLeft: "1rem", paddingRight: "1rem"}}>
						<Typography variant="title8" sx={{ display: '-webkit-box', overflow: 'hidden', WebkitBoxOrient: 'vertical', WebkitLineClamp: 1 }}>
							{product.name}
						</Typography>
						<Typography variant="body2" sx={{ display: '-webkit-box', overflow: 'hidden', WebkitBoxOrient: 'vertical', WebkitLineClamp: 2 }}>
							{product.description}
						</Typography>
						{product.hasDiscount && product.hasDiscount == true ?
							<Box item sx={{ display: "flex", flexDirection: "row", alignItems: "flex-end" }}>
								<Typography variant="caption1" sx={{ color: colors.medium_gray, textDecorationLine: "line-through" }}>${product.previousPrice ? product.previousPrice.toLocaleString("en-US") : 0}&nbsp;</Typography>
							</Box> 
						:null}
						<Box sx={{ display: "flex", flexDirection: "row", justifyContent: "left", alignItems: "center" }}>
							{product.price && product.price != 0 && product.price != "" && product.price != null ?
								<Typography variant="title7">${product.price.toLocaleString("en-US")}</Typography>
							:null}
							{product.discountPercentageVisible && product.hasDiscount && product.hasDiscount == true && product.previousPrice && parseFloat(product.previousPrice) > 0 ? 
								<Box style={{display: "flex", flexDirection: "row", alignItems: "center", marginLeft: "8px"}}>
									<Typography variant='body3' style={{color: colors.primary_green, fontSize: "12px"}}>
										%{percentageDifference(product.previousPrice, product.price)} OFF
									</Typography>
									<Box sx={{marginLeft: "8px"}}>
										<img 
											src={Discount} 
											height={18} 
											width={18} 
											alt=''
										/>
									</Box>
								</Box>
							:<></>}
						</Box>
						<Box style={{display: "flex", flexDirection: "row", flexWrap: "wrap"}}>
							{product.free_shipping ? <img 
								src={FreeShipping} 
								height={18} 
								width={18} 
								alt=''
								style={{marginRight: "4px"}}
							/> : <></>}
							{product.msi ? <img 
								src={MSI} 
								height={18} 
								width={18} 
								alt=''
								style={{marginRight: "4px"}}
							/> : <></>}
							{product.promotion ? <img 
								src={Offer} 
								height={18} 
								width={18} 
								alt=''
								style={{marginRight: "4px"}}
							/> : <></>}
						</Box>
					</div>
				</div>
			))
		}else{
			return <div style={{width: "100%", height: "5rem", display: "flex", justifyContent: "center", alignItems: "center"}}>
				<Typography sx={{color: "black"}}>
					No hubo resultados de productos
				</Typography>
			</div>
		}
	}

	const _renderStores = () => {
		if(stores.length){
			return stores.map((store,index)=>(
				<div onClick={()=>openStore(store)} key={`store-algolia-${index}`} style={{height:"8rem", width: "5.2rem", minWidth: "5.2rem",display: "flex", flexDirection: "column", alignItems: "center", paddingTop: "0.5rem", cursor: "pointer"}}>
					<div style={{height: "4rem", width: "4rem", borderRadius: "2rem", overflow: "hidden", backgroundColor: store.backgroundColor ? "#" + store.backgroundColor : "#1FADC1"}}>
						<img
							alt="img"
							src={store.logo + imgSuffix.md}
							style={{display: "flex", width: "4rem", objectFit: "cover", height: "4rem", aspectRatio: "1:1"}}
						/> 
					</div>
					<div style={{width: "100%", paddingTop: "0.2rem"}}>
						<Typography sx={{fontSize: "12px", color: "black", textAlign: "center"}}>{store.name}</Typography>
					</div>
				</div>
			))
		}else{
			return <div style={{width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center"}}>
				<Typography sx={{color: "black"}}>
					No hubo resultados de tiendas
				</Typography>
			</div>
		}
	}

	const convertRemToPixels = (rem) => {
		return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
	}

	if(overlay_visible){
		if(!stores.length && !products.length && !loading_products && !loading_stores){
			return (
				<div style={{position: "absolute", maxHeight: window.innerHeight, width: isMobile ? "100%" : "24rem", backgroundColor: "white", top: "8rem", left: isMobile ? 0 : "12rem", zIndex: 20000, borderRadius: "0px 0px 8px 8px", boxShadow: "0px 10px 15px -3px rgba(0,0,0,0.1)", overflowY: "scroll", overflowX: "hidden"}}>
					<div style={{width: "90%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center", margin: "1rem"}}>
						<Typography sx={{color: "black"}}>
							La búsqueda no obtuvo resultados, revisa y ajusta tu consulta.
						</Typography>
					</div>
				</div>	
			)
		}else{
			return (
				<div style={{position: "absolute", height: isMobile ? window.innerHeight - convertRemToPixels(8) : "32rem", maxHeight: window.innerHeight, width: isMobile ? "100%" : "24rem", backgroundColor: "white", top: "8rem", left: isMobile ? 0 : "12rem", zIndex: 20000, borderRadius: "0px 0px 8px 8px", boxShadow: "0px 10px 15px -3px rgba(0,0,0,0.1)", overflowY: "scroll", overflowX: "hidden"}}>
					{stores.length ?
						<div>
							<div style={{display: "flex", flexDirection: "row", width: "100%", justifyContent: "space-between"}}>
								<Typography style={{fontSize: "16px", marginLeft: "1rem", fontWeight: "bold", color: "black", marginBottom: "0.5rem", marginTop: "1rem"}}>
									Tiendas
								</Typography>
								<div onClick={closeSearch} style={{height:"3rem", width: "3rem", display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer"}}>
									<Close sx={{color: colors.medium_gray}}/>
								</div>
							</div>
							{loading_stores ? 
								<CircularProgress style={{color: colors.primary_green}}/>
							:
								<div style={{display: "flex", flexDirection: "row", overflowX: "scroll", overflowY: "hidden", height: "8rem", backgroundColor: colors.light_background, scrollBehavior: "unset"}}>
									{_renderStores()}
								</div>
							}
						</div>
					:null}
					{products.length ?
						<div>
							<Typography style={{fontSize: "16px", marginLeft: "1rem", fontWeight: "bold", color: "black", marginBottom: "0.5rem", marginTop: "0.5rem"}}>
								Productos
							</Typography>
							{loading_products ? 
								<CircularProgress style={{color: colors.primary_green}}/>
							:
								_renderProducts()
							}
						</div>
					:null}
				</div>
			)
		}
	}else{
		return <></>
	}
}