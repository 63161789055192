import colors from 'theme/colors';

let sx = {
	mainContainer: {
		backgroundColor: colors.main_white,
		paddingTop: "78px",
		// paddingBottom: "48px",
		display: "flex",
		flexDirection: "column",
		alignItems: "center"
	},
	mainContainerMobile: {
		backgroundColor: colors.main_white,
		paddingTop: "16px",
		// paddingBottom: "48px",
		display: "flex",
		flexDirection: "column",
		alignItems: "center"
	},
	mainGrid: {
		// marginTop: "85px",
		// marginBottom: "48px",
	},
	gridItem: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
	},
	videoGrid: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		padding: "24px"
	},
	textGrid: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
	},
	counterBox: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "center"
	},
	counterInfo: {
		display: "flex",
		flexDirection: "column",
		marginLeft: "16px"
	},
	counterItem: {
		marginTop: "56px",
		marginBottom: "112px",
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
		maxWidth: "954px",
		width: "100%",
		flexWrap: "wrap"
	},
	counterItemMobile: {
		marginTop: "16px",
		marginBottom: "16px",
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
		maxWidth: "954px",
		width: "100%",
		flexWrap: "wrap"
	},
	graySpacer: {
		width: "100%",
		backgroundColor: colors.medium_light_gray,
		height: "1px",
		maxWidth: "1440px"
	}
}

export default sx;