module.exports = `<!DOCTYPE html>
<html>
<head>
	<meta http-equiv="content-type" content="text/html; charset=utf-8"/>
	<title></title>
	<meta name="generator" content="LibreOffice 7.6.3.2 (Linux)"/>
	<meta name="created" content="00:00:00"/>
	<meta name="changed" content="00:00:00"/>
	<style type="text/css">
		@page { size: 8.5in 11in; margin: 1in }
		p { line-height: 115%; text-align: left; orphans: 2; widows: 2; margin-bottom: 0.1in; direction: ltr; background: transparent }
	</style>
</head>
<body lang="en-US" link="#000080" vlink="#800000" dir="ltr">
    <div ng-controller="privacyTermsInternalController as vm" class="container-fluid termsAndConditions-styles">
    <div class="row">
        <div class="col-xs-12">
            <div class="row">
                <div class="col-xs-12">
                    <div class="div-containerTermsAndConditions">
                        <span class="modaltitle-termscond">
                        <font face="Times New Roman, serif"><font size="1" style="font-size: 12pt">
                            ELIMINAR CUENTA TREEMBO
                        </font></font>
                        </span>
                        <br>
                        <br>
                        <span class="modaltitle-termscond">
                        <font face="Times New Roman, serif"><font size="1" style="font-size: 12pt">
                            Para solicitar la eliminación de su cuenta Treembo, es necesario utilizar el formulario en el menú de opciones de la aplicación móvil disponible en tiendas App Store para sistema operativo iOS y Play Store para sistema operativo Android.
                        </font></font>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</body>
</html>`;