import { CircularProgress, Container } from "@mui/material";

export default function LoadingPage() {

  return (
    <Container maxWidth="sm" id="error-page" sx={{display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", width: "100%", height: "100%", paddingTop: "10rem"}}>
        <CircularProgress/>
        <p>Cargando...</p>
    </Container>
  );
}